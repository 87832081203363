import * as React from 'react';

import { MaterialCommunityIcons, FontAwesome, Ionicons } from '@expo/vector-icons';

import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { createStackNavigator } from '@react-navigation/stack';

import Colors from '../constants/Colors';
import useColorScheme from '../hooks/useColorScheme';

import { IS_DEBUG } from '../constants/Config';

import QuestionScreen from '../screens/QuestionScreen';
import ReportScreen from '../screens/ReportScreen';
import ReportQuestionScreen from '../screens/ReportQuestionScreen';
import ReportOxfordScreen from '../screens/ReportOxfordScreen';
import ReportOxfordDetailsScreen from '../screens/ReportOxfordDetailsScreen';
import RegisterScreen from '../screens/RegisterScreen';
import ThanksScreen from '../screens/ThanksScreen';
import FamilyScreen from '../screens/FamilyScreen';
import ProfileScreen from '../screens/ProfileScreen';
import ConfigScreen from '../screens/ConfigScreen';
import LoginScreen from '../screens/LoginScreen';
import LogoutScreen from '../screens/LogoutScreen';
import AssistantScreen from '../screens/AssistantScreen';

import { 
  BottomTabParamList, 
  TabQuestionParamList, 
  TabReportParamList, 
  TabFamilyParamList,
  TabProfileParamList,
  TabConfigParamList,
} from '../types';


//--------------------------------------------------------------------------------
// Check if user is already login by checkin session values
//--------------------------------------------------------------------------------
const getIsSignedIn = () => {
  try {
    let email = sessionStorage.getItem('email');
    let uid = sessionStorage.getItem('uid');
    let fid = sessionStorage.getItem('fid');
    let cc = sessionStorage.getItem('cc');
    
    if ( email && uid && fid  ) {
      IS_DEBUG && console.log(`getIsSignedIn() >> yes > email=${email} uid=${uid} fid=${fid} cc=${cc}`);
      return true;
    }
    else {
      IS_DEBUG && console.log(`getIsSignedIn() >> no > email=${email} uid=${uid} fid=${fid} cc=${cc}`);
      return false;
    }
  } catch (error) {
    console.log(`[ERR] getIsSignedIn() error=${error}`);
    return false;
  }
};




//--------------------------------------------------------------------------------
// Default Export function - BottomTab
//--------------------------------------------------------------------------------
const BottomTab = createBottomTabNavigator<BottomTabParamList>();

export default function BottomTabNavigator({ navigation }) {
  const colorScheme = useColorScheme();
  const isSignedIn = getIsSignedIn();

  /* const cc = sessionStorage.getItem('cc') || 'en'; */
  //IS_DEBUG && console.log(`BottomTabNavigator >> cc=${cc}`);

  return (
      <BottomTab.Navigator
        initialRouteName="Login"
        tabBarOptions={{ activeTintColor: Colors[colorScheme].tint }}
      >

          {isSignedIn ? (
          <>
            <BottomTab.Screen
              name="Question"
              component={TabQuestionNavigator}
              /*cc={cc}*/
              /*component={(props) => <TabQuestionNavigator {...props} />}*/
              /*component={(props) => <TabQuestionNavigator {...props} cc={cc} />}*/
              options={{tabBarIcon: ({ color }) => <TabBarIcon name="ios-happy" color={color} />}}
            />
            <BottomTab.Screen
              name="Report"
              component={TabReportNavigator}
              options={{tabBarIcon: ({ color }) => <TabBarIcon name="ios-bar-chart" color={color} />}}
            />
            <BottomTab.Screen
              name="Assistant"
              component={AssistantScreen}
              options={{tabBarIcon: ({ color }) => <MaterialCommunityIcons name="robot" size={30} color={color} />}}
            />
            <BottomTab.Screen
              name="Profile"
              component={TabProfileNavigator}
              options={{tabBarIcon: ({ color }) => <TabBarIcon name="ios-person" color={color} />}}
            />
            <BottomTab.Screen
              name="Config"
              component={TabConfigNavigator}
              options={{
                tabBarIcon: ({ color }) => 
                  <FontAwesome name="gear"  style={{ marginBottom: -3}} 
                    size={30}
                    color={color} />
              }}
            />
            <BottomTab.Screen
              name="Logout"
              component={LogoutScreen}
              options={{
                tabBarIcon: ({ color }) => <TabBarIcon name="ios-log-out" color={color} />,
                tabBarVisible: false,
              }}
            />
          </>
        ) : (
          <>
            <BottomTab.Screen
              name="Login"
              component={LoginScreen}
              options={{tabBarVisible: false}}
            />
            <BottomTab.Screen
                name="Register"
                component={RegisterScreen}
                options={{tabBarVisible: false}}
            />
          </>
          )}
    </BottomTab.Navigator>
  );
}

//--------------------------------------------------------------------------------
// You can explore the built-in icon families and icons on the web at:
// https://icons.expo.fyi/
//--------------------------------------------------------------------------------
function TabBarIcon(props: { name: React.ComponentProps<typeof Ionicons>['name']; color: string }) {
  return <Ionicons size={30} style={{ marginBottom: -3 }} {...props} />;
}

//--------------------------------------------------------------------------------
// Each tab has its own navigation stack, you can read more about this pattern here:
// https://reactnavigation.org/docs/tab-based-navigation#a-stack-navigator-for-each-tab
//--------------------------------------------------------------------------------

//--------------------------------------------------------------------------------
// TabQuestionStack
//--------------------------------------------------------------------------------
const TabQuestionStack = createStackNavigator<TabQuestionParamList>();

const email = sessionStorage.getItem('email');
const uid = sessionStorage.getItem('uid');
const fid = sessionStorage.getItem('fid');
const cc = sessionStorage.getItem('cc');

function TabQuestionNavigator(/*{ cc }*/) {
  IS_DEBUG && console.log(`TabQuestionNavigator >> cc=${cc}`);
  return (
    <TabQuestionStack.Navigator
      screenOptions={{ 
        headerStyle: {backgroundColor: '#003f5c'},
        headerTintColor: "#fb5b5a",
        headerTitleStyle: {fontWeight: 'bold'},
      }} 
    >
      <TabQuestionStack.Screen
        name="Question"
        /*component={QuestionScreen}*/
        component={(props) => <QuestionScreen {...props} cc={cc} />}
        initialParams={{ uid:uid, fid:fid, email:email, cc:cc}}
        cc={cc}
        options={{ title: 'Question' }}
      />
      <TabQuestionStack.Screen
        name="Thanks"
        component={ThanksScreen}
        options={{ title: 'Thanks' }}
      />
    </TabQuestionStack.Navigator>
  );
}

//--------------------------------------------------------------------------------
// TabReportStack
//--------------------------------------------------------------------------------
const TabReportStack = createStackNavigator<TabReportParamList>();

function TabReportNavigator() {
  return (
    <TabReportStack.Navigator
      screenOptions={{ 
        headerStyle: {backgroundColor: '#003f5c'},
        headerTintColor: "#fb5b5a",
        headerTitleStyle: {fontWeight: 'bold'},
      }}
    >
      <TabReportStack.Screen
        name="Report"
        component={ReportScreen}
        options={{ title: 'Family Report' }}
      />
      <TabReportStack.Screen
        name="ReportQuestion"
        component={ReportQuestionScreen}
        options={{ title: 'Question Report' }}
      />
      <TabReportStack.Screen
        name="ReportOxford"
        component={ReportOxfordScreen}
        options={{ title: 'Oxford Happiness Reports' }}
      />
      <TabReportStack.Screen
        name="ReportOxfordDetails"
        component={ReportOxfordDetailsScreen}
        options={{ title: 'Oxford Happiness Report' }}
      />
      
    </TabReportStack.Navigator>
  );
}



//--------------------------------------------------------------------------------
// TabProfileStack
//--------------------------------------------------------------------------------
const TabProfileStack = createStackNavigator<TabProfileParamList>();

function TabProfileNavigator() {
  return (
    <TabProfileStack.Navigator
      screenOptions={{ 
        headerStyle: {backgroundColor: '#003f5c'},
        headerTintColor: "#fb5b5a",
        headerTitleStyle: {fontWeight: 'bold'},
      }}
    >
      <TabProfileStack.Screen
        name="Profile"
        component={ProfileScreen}
        options={{ title: 'Profile' }}
      />
      <TabProfileStack.Screen
        name="Family"
        component={FamilyScreen}
        options={{ title: 'Family' }}
      />

    </TabProfileStack.Navigator>
  );
}

//--------------------------------------------------------------------------------
// TabConfigStack
//--------------------------------------------------------------------------------
const TabConfigStack = createStackNavigator<TabConfigParamList>();

function TabConfigNavigator() {
  return (
    <TabConfigStack.Navigator
      screenOptions={{ 
        headerStyle: {backgroundColor: '#003f5c'},
        headerTintColor: "#fb5b5a",
        headerTitleStyle: {fontWeight: 'bold'},
      }}
    >
      <TabConfigStack.Screen
        name="Config"
        component={ConfigScreen}
        options={{ title: 'Config' }}
      />

    </TabConfigStack.Navigator>
  );
}
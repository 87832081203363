import * as Linking from 'expo-linking';

export default {
  prefixes: [Linking.makeUrl('/')],
  config: {
    screens: {
      Root: {
        screens: {
          TabQuestion: {
            screens: {
              QuestionScreen: 'Question',
            },
          },
          TabReport: {
            screens: {
              ReportScreen: 'Report',
            },
          },
          TabRegister: {
            screens: {
              RegisterScreen: 'Register',
            },
          },
          TabThanks: {
            screens: {
              ThanksScreen: 'Thanks',
            },
          },
          TabLogin: {
            screens: {
              LoginScreen: 'Login',
            },
          },
          TabFamily: {
            screens: {
              FamilyScreen: 'Family',
            },
          },
          TabProfile: {
            screens: {
              ProfileScreen: 'Profil',
            },
          },
        },
      },
      NotFound: '*',
    },
  },
};

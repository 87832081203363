import { StackScreenProps } from '@react-navigation/stack';
import * as React from 'react';
import { ScrollView, Text, TouchableOpacity, View, Image } from 'react-native';
import { RootStackParamList } from '../types';
import { styles } from '../assets/css/styles';
import { IS_DEBUG } from '../constants/Config';
import AdComponent from '../components/AdComponent';
import ReactGA from 'react-ga4';

export default function NotFoundScreen({navigation,}: 
  StackScreenProps<RootStackParamList, 'NotFound'>) {

  // init
  React.useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      // tracking
      const currentPath = window.location.pathname + window.location.search;
      ReactGA.send({hitType: "pageview", page: currentPath, title: "NotFoundScreen"});
    });
    return unsubscribe;
  }, [navigation]);
  

  return (
    <ScrollView style={styles.container_scroll}>
      <View style={styles.container}>
        
        <TouchableOpacity onPress={() => navigation.replace('Root')} style={styles.link}>
          <Image
              source={{ uri: "https://reactnative.dev/docs/assets/p_cat1.png" }}
              style={{ width: 200, height: 200 }}
          />
          <View style={styles.centeredContent}>
            <Text style={styles.title}>Welcome back.</Text>
            <View style={styles.separator} />
            <Text style={styles.linkText}>Please start at home screen!</Text>
          </View>
        </TouchableOpacity>

        <AdComponent />

      </View>
    </ScrollView>
  );
}


import React, { useEffect } from 'react';

const AdComponent = () => {
  useEffect(() => {
    if (window) {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    }
  }, []);

  return (
    <div>
        <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9444736005864343"
            crossorigin="anonymous"></script>
        <ins
            className="adsbygoogle"
            style={{ display: 'block' }}
            data-ad-client="ca-pub-9444736005864343"
            data-ad-slot="9444736005864343"
            data-ad-format="auto"
        ></ins>
    </div>
  );
};

export default AdComponent;

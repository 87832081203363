import * as React from 'react';
import { TouchableOpacity, ScrollView } from 'react-native';

import { BASE_URL, IS_DEBUG } from '../constants/Config';
import { translations } from '../constants/translations';
import { Text, View } from '../components/Themed';
import { styles, getColorStyle } from '../assets/css/styles';
import { DataTable } from 'react-native-paper';
import { handleCcChange } from '../utils/ChangeHandlers';

import BarChart from '../utils/BarChart';
import LineChart from '../utils/LineChart';
import ReactGA from 'react-ga4';



//------------------------------------------------------------------------------
// Main  
//------------------------------------------------------------------------------
export default function ReportOxfordScreen({navigation, route}) {

  // session
  const uid = sessionStorage.getItem('uid');
  const fid = sessionStorage.getItem('fid');
  const email = sessionStorage.getItem('email');
  // translation
  const [cc, setCc] = React.useState( sessionStorage.getItem('cc') );
  let t = translations[cc] || translations.en;
  // states
  const [reports, setReports] = React.useState<Reports | null>(null);

  // --------------------------------------------------------------------------------
  // handle cc changes
  // --------------------------------------------------------------------------------
  React.useEffect(() => {
    const wrappedHandleCcChange = () => handleCcChange(setCc);
    window.addEventListener('ccChange', wrappedHandleCcChange);
    return () => {
      window.removeEventListener('ccChange', wrappedHandleCcChange);
    };
  }, [cc]);

  // --------------------------------------------------------------------------------
  // data loading and tracking
  // --------------------------------------------------------------------------------
  React.useEffect(() => {
    // getOxfordReport
    getOxfordReports(fid, cc);
    // tracking
    const unsubscribe = navigation.addListener('focus', () => {
      const currentPath = window.location.pathname + window.location.search;
      ReactGA.send({hitType: "pageview", page: currentPath, title: "ReportOxfordScreen"});
    });
    return unsubscribe;
  }, [navigation, cc]);

  
  
  const getOxfordReports = async (fid, cc) => {
    const url = `${BASE_URL}php/get_reports_oxford.php`;
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: `fid=${fid}&cc=${cc}`,
      });
      
      const rspJson = await response.json();
      IS_DEBUG && console.log(`get_reports_oxford >> rsp=${rspJson} fid=${fid} cc=${cc}`);
  
      if (rspJson.rc === 1 ) {  
        setReports(rspJson.reports);
      } else {
        console.log("[ERR] rc=", rspJson.rc);
      }
    } catch (error) {
      console.error("[ERR] ",error);
    }
  }

  const showOxfordDetails = (rid) => {
    navigation.navigate('ReportOxfordDetails', {rid:rid});
  }

  // return table rows
  const ReportDataTableRow = (props) => (
    reports && reports.map(report => {
      return <DataTable.Row key={report.id}>
          <DataTable.Cell style={getColorStyle(report.idx)}>{report.idx}</DataTable.Cell>
          <DataTable.Cell>{report.email}</DataTable.Cell>
          <DataTable.Cell>{report.date}</DataTable.Cell>
          <DataTable.Cell>
            <TouchableOpacity onPress={() => showOxfordDetails(report.id)}>
              <Text>Details</Text>
            </TouchableOpacity>
          </DataTable.Cell>
        </DataTable.Row>;
    })
  )

  let view = (
    <ScrollView>
      <View style={styles.container}>
        
        <Text style={styles.text4}>Oxford Reports</Text>
        <View style={styles.separator} />

        <View style={styles.container1024}>
          
          <DataTable>
              <DataTable.Header >
                  <DataTable.Title><Text style={styles.text2}>Points</Text></DataTable.Title>
                  <DataTable.Title><Text style={styles.text2}>Name</Text></DataTable.Title>
                  <DataTable.Title><Text style={styles.text2}>Date</Text></DataTable.Title>
                  <DataTable.Title><Text style={styles.text2}>Action</Text></DataTable.Title>
              </DataTable.Header>
              <ReportDataTableRow />
          </DataTable>

          <View style={styles.separator} />

          <Text style={styles.text2}>{t.oxfordAnalysisTitle}</Text>

          
          <Text style={styles.text1}>
              <b>1 - 2 Points:</b><br />
              {t.oxfordLevel0}
          </Text>
          <br />
          <Text style={styles.text1}>
              <b>2 - 3 Points:</b><br />
              {t.oxfordLevel1}
          </Text>
          <br />
          <Text style={styles.text1}>
              <b>3 - 4 Points:</b><br />
              {t.oxfordLevel2}
          </Text>
          <br />
          <Text style={styles.text1}>
              <b>4 Points:</b><br />
              {t.oxfordLevel3}
          </Text>
          <br />
          <Text style={styles.text1}>
              <b>4 - 5 Points:</b><br />
              {t.oxfordLevel4}
          </Text>
          <br />
          <Text style={styles.text1}>
              <b>5 - 6 Points:</b><br />
              {t.oxfordLevel5}
          </Text>
          <br />
          <Text style={styles.text1}>
              <b>6 Points:</b><br />
              {t.oxfordLevel6}
          </Text>
        

          <View style={styles.separator} />

          <View style={styles.footer}>
            <Text style={styles.footer_text}>Logged in as {email}</Text>
          </View>

        </View> {/* container1024 */}

      </View> {/* container */}
    </ScrollView>
  );

  return view;
}

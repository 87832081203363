import * as React from 'react';
import { ScrollView, TouchableOpacity } from 'react-native';
import { styles, getColorBox } from '../assets/css/styles';
import { BASE_URL, SOURCE_OXFORD_NUM_QUESTIONS, IS_DEBUG } from '../constants/Config';
import { translations } from '../constants/translations';
import { handleCcChange } from '../utils/ChangeHandlers';

import { Text, View } from '../components/Themed';
import BarChart from '../utils/BarChart';
import LineChart from '../utils/LineChart';
import ReactGA from 'react-ga4';


interface ReportQuestion {
    question: string;
    qid: number;
    category: string;
    votes: number;
    index: number;
}
interface Distribution {
  v1: number;
  v2: number;
  v3: number;
  v4: number;
  v5: number;
  votes: number;
  index: number;
  percent: number;
}

interface History {
  data: number[];
  votes: number;
}

export default function ReportScreen({navigation, route}) {

  // session
  const uid = sessionStorage.getItem('uid');
  const fid = sessionStorage.getItem('fid');
  const email = sessionStorage.getItem('email');
  // translation
  const [cc, setCc] = React.useState( sessionStorage.getItem('cc') );
  let t = translations[cc] || translations.en;

  // Mood (Stimmungsindex)
  const [moodDistribution, setMoodDistribution] = React.useState<Distribution | null>(null);
  const [moodHistory, setMoodHistory] = React.useState<History | null>(null);
  
  // All Questions summary (Frageindex)
  const [allQuestion, setAllQuestion] = React.useState<Distribution | null>(null);
  // top 3 questions
  const [top1, setTop1] = React.useState<ReportQuestion | null>(null);
  const [top2, setTop2] = React.useState<ReportQuestion | null>(null);
  const [top3, setTop3] = React.useState<ReportQuestion | null>(null);
  // bottom 3 questions
  const [bottom1, setBottom1] = React.useState<ReportQuestion | null>(null);
  const [bottom2, setBottom2] = React.useState<ReportQuestion | null>(null);
  const [bottom3, setBottom3] = React.useState<ReportQuestion | null>(null);
  
  //  period
  const [period, setPeriod] = React.useState('month');
  
  // oxford
  const [oxford, setOxford] = React.useState(null);


  // --------------------------------------------------------------------------------
  // handle cc changes
  // --------------------------------------------------------------------------------
  React.useEffect(() => {
    const wrappedHandleCcChange = () => handleCcChange(setCc);
    window.addEventListener('ccChange', wrappedHandleCcChange);
    return () => {
      window.removeEventListener('ccChange', wrappedHandleCcChange);
    };
  }, [cc]);

  // --------------------------------------------------------------------------------
  // data loading and tracking
  // --------------------------------------------------------------------------------
  React.useEffect(() => {
    // get report
    IS_DEBUG && console.log('ReportScreen useEffect >> getReport()');
    getReport(fid, period, cc);
    //tracking
    const unsubscribe = navigation.addListener('focus', () => {  
      const currentPath = window.location.pathname + window.location.search;
      ReactGA.send({hitType: "pageview", page: currentPath, title: "ReportScreen"});
    });
    return unsubscribe;
  }, [cc]);


  /**
   * Limit results to period
   * @param period - week,month,quarter,year,all
   */
  const handlePeriodLinkPress = (period) => {
    setPeriod(period);
    getReport(fid,period,cc);
  };


  /**
   * List details of questions such as show bar chart
   * @param qid - question id
   */
  const handleQuestionDetails = (qid) => {
    IS_DEBUG && console.log(`handleQuestionDetails() qid=${qid}`);
    navigation.navigate('ReportQuestion', {qid:qid, period:period, fid:fid});
  };
  
  /**
   * create oxford report for user uid
   */ 
  const createOxfordReport = async (uid) => {
    const url = BASE_URL+'php/create_oxford_report.php';
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: `uid=${uid}&fid=${fid}`,
      });
      const rspJson = await response.json();
      if (rspJson.rc === 1 ) {  
        IS_DEBUG && console.log(`[OK] createOxfordReport rc=${rspJson.rc}`);
        navigation.navigate('ReportOxford', {fid:fid});
      } else {
        console.log(`[ERR] rc=${rspJson.rc} msg=${rspJson.msg}`);
      }
    } catch (error) {
      console.log(`[ERR] error=${error}`);
    }
  };

  // show oxford reports
  const showOxfordReports = (fid) => {
    navigation.navigate('ReportOxford', {fid:fid});
  };

  /*******************************************************************************
  * Get report
  * TODO: parent index
  * TODO: kids index
  * TODO: trend: up/down 10% compared to last report. Filter on time!
  * TODO: nice chart to show mood change over time
  *******************************************************************************/
  const getReport = async (fid, period, cc) => {
    const url = `${BASE_URL}php/get_report.php`;
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: `fid=${fid}&period=${period}&cc=${cc}`,
      });
      
      const rspJson = await response.json();
      IS_DEBUG && console.log(`get_report >> rsp=${rspJson} fid=${fid} period=${period} cc=${cc}`);

      if (rspJson.rc === 1 )
      {  
        // Mood Index
        if (rspJson.mood ) {
          setMoodDistribution(rspJson.mood.distribution);
          setMoodHistory(rspJson.mood.history);
        }

        // All Question Index
        if (rspJson.allQuestion ) setAllQuestion(rspJson.allQuestion);
        // top 3 questions
        if (rspJson.top1 ) setTop1(rspJson.top1);
        if (rspJson.top2 ) setTop2(rspJson.top2);
        if (rspJson.top3 ) setTop3(rspJson.top3);
        // bottom 3 questions
        if (rspJson.bottom1 ) setBottom1(rspJson.bottom1);
        if (rspJson.bottom2 ) setBottom2(rspJson.bottom2);
        if (rspJson.bottom3 ) setBottom3(rspJson.bottom3);

        // oxford
        setOxford(rspJson.oxford);
      } else {
        console.log("[ERR] rc=", rspJson.rc);
      }
    } catch (error) {
      console.error("[ERR] ",error);
    }
  }

  
  const topQuestions = [top1, top2, top3];
  const bottomQuestions = [bottom1, bottom2, bottom3];
  
  

  let view = (
    <ScrollView>
      <View style={styles.container}>

          <Text style={styles.text4}>{t.reportSatisfactionAnalysis}</Text>
          <View style={{ flexDirection: 'row', justifyContent: 'space-around'}}>
            <TouchableOpacity onPress={() => handlePeriodLinkPress('week')}>
              <Text style={period === 'week' ? { fontWeight: 'bold' } : null}>Week</Text>
            </TouchableOpacity>
            &#160;|&#160;
            <TouchableOpacity onPress={() => handlePeriodLinkPress('month')}>
              <Text style={period === 'month'  ? { fontWeight: 'bold' } : null}>Month</Text>
            </TouchableOpacity>
            &#160;|&#160;
            <TouchableOpacity onPress={() => handlePeriodLinkPress('quarter')}>
              <Text style={period === 'quarter' ? { fontWeight: 'bold' } : null}>Quarter</Text>
            </TouchableOpacity>
            &#160;|&#160;
            <TouchableOpacity onPress={() => handlePeriodLinkPress('year')}>
              <Text style={period === 'year' ? { fontWeight: 'bold' } : null}>Year</Text>
            </TouchableOpacity>
            &#160;|&#160;
            <TouchableOpacity onPress={() => handlePeriodLinkPress('all')}>
              <Text style={period === 'all' ? { fontWeight: 'bold' } : null}>Total</Text>
            </TouchableOpacity>
          </View>

          <View style={styles.separator} />

          <Text style={styles.text3}>{t.reportMoodIndex}</Text>
          {moodDistribution && (
            <React.Fragment>
              <Text style={styles.text2}>
                <View style={getColorBox(moodDistribution.index)}></View> 
                {moodDistribution.percent}% Positiv ({moodDistribution.index} / 5)
              </Text>
              <BarChart vote={moodDistribution} />
              <Text style={styles.text1}>
                {moodDistribution.votes} total votes
              </Text>
            </React.Fragment>
          )}
          <Text style={styles.text3}>{t.reportMoodOverTime}</Text>
          {moodHistory && (
            <React.Fragment>
              <LineChart history={moodHistory.data} />
              <Text style={styles.text1}>
                {moodHistory.votes} total votes
              </Text>
            </React.Fragment>
          )}

          <View style={styles.separator} />

          <Text style={styles.text3}>{t.reportQuestionIndex}</Text>
          {allQuestion && (
            <React.Fragment>
              <Text style={styles.text2}>
                <View style={getColorBox(allQuestion.index)}></View>
                {allQuestion.percent}% Positiv ({allQuestion.index} / 5)
              </Text>
              <BarChart vote={allQuestion}/>
              <Text style={styles.text1}>
                {allQuestion.votes} total votes
              </Text>
            </React.Fragment>
          )}


          <View style={styles.separator} />

          <Text style={styles.text3}>{t.reportTopQuestions}</Text>
          {topQuestions.map((top, index) => (
            top && (
              <React.Fragment key={index}>
                <Text style={styles.text2}>{top.question} [{top.category}]</Text>
                <Text style={styles.text1}>
                  <View style={getColorBox(top.index)}></View> 
                  {top.index} / 5 ({top.votes} Votes)
                </Text>
                <TouchableOpacity onPress={() => handleQuestionDetails(top.qid)}>
                  <Text>Details &#187;</Text>
                </TouchableOpacity>
              </React.Fragment>
            )
          ))}

          <View style={styles.separator} />

          <Text style={styles.text3}>{t.reportBottomQuestions}</Text>
          {bottomQuestions.map((bottom, index) => (
            bottom && (
              <React.Fragment key={index}>
                <Text style={styles.text2}>{bottom.question} [{bottom.category}]</Text>
                <Text style={styles.text1}>
                  <View style={getColorBox(bottom.index)}></View> 
                  {bottom.index} / 5 ({bottom.votes} Votes)
                </Text>
                <TouchableOpacity onPress={() => handleQuestionDetails(bottom.qid)}>
                  <Text>Details &#187;</Text>
                </TouchableOpacity>
              </React.Fragment>
            )
          ))}

          <View style={styles.separator} />

          <Text style={styles.text3}>Oxford Happiness Test</Text>
          {oxford && oxford.length > 0 && (
            oxford.map((user, index) => (
              <React.Fragment key={index}>
                <Text style={styles.text2}>
                  {user.email}: {user.percentage}% done
                  [{user.votes}/{SOURCE_OXFORD_NUM_QUESTIONS}]
                </Text>
                { user.percentage === 100 ? (
                <TouchableOpacity onPress={() => createOxfordReport(user.uid)}>
                  <Text>Create Report &#187;</Text>
                </TouchableOpacity>
                ) : (
                  <Text style={styles.text1}>Report avaiable when all questions answered</Text>
                )}
              </React.Fragment>
            ))
          )}
          <Text>&#160;</Text>
          <TouchableOpacity onPress={() => showOxfordReports(fid)}>
            <Text>Historical Reports &#187;</Text>
          </TouchableOpacity>

          <View style={styles.separator} />

          <Text style={styles.text2}>{t.reportExplanationTitle}</Text>
          <Text style={styles.text1}>
            {t.reportExplanation}
          </Text>

          <Text style={styles.text1}>&#160;</Text>

          <Text style={styles.text1}>Score</Text>
          <Text style={styles.text1}>{t.scoreNote}</Text>
          <Text style={styles.text0}>
            <View style={styles.level8_box}></View> 8&#160;
            <View style={styles.level7_box}></View> 7&#160;
            <View style={styles.level6_box}></View> 6&#160;
            <View style={styles.level5_box}></View> 5&#160;
            <View style={styles.level4_box}></View> 4&#160;
            <View style={styles.level3_box}></View> 3&#160;
            <View style={styles.level2_box}></View> 2&#160;
            <View style={styles.level1_box}></View> 1&#160;
            <View style={styles.level0_box}></View> 0&#160;
          </Text>

          <View style={styles.separator} />

        <View style={styles.footer}>
          <Text style={styles.footer_text}>Logged in as {email}</Text>
        </View>

      </View>
    </ScrollView>
  );

  return view;
}



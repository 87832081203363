import * as React from 'react';
import { StyleSheet, ScrollView, TextInput, Image, TouchableOpacity, Button, View, Text} from 'react-native';
import { styles } from '../assets/css/styles';
import { DataTable } from 'react-native-paper';
import { BASE_URL, ROLE_ADULT_ADMIN, IS_DEBUG } from '../constants/Config';
import { translations } from '../constants/translations';
import { handleCcChange } from '../utils/ChangeHandlers';
import ReactGA from 'react-ga4';

export default function FamilyScreen({ navigation, route }) {

  // session values
  const fid = sessionStorage.getItem('fid');
  const email = sessionStorage.getItem('email');
  // translation
  const [cc, setCc] = React.useState( sessionStorage.getItem('cc') );
  let t = translations[cc] || translations.en;
  // family states
  const [currFamily, setCurrFamily] = React.useState(null);
  const [members, setMembers] = React.useState([]);
  const [guestEmail, setGuestEmail] = React.useState(null);

  
  // --------------------------------------------------------------------------------
  // handle cc changes
  // --------------------------------------------------------------------------------
  React.useEffect(() => {
    const wrappedHandleCcChange = () => handleCcChange(setCc);
    window.addEventListener('ccChange', wrappedHandleCcChange);
    return () => {
      window.removeEventListener('ccChange', wrappedHandleCcChange);
    };
  }, [cc]);

  // --------------------------------------------------------------------------------
  // data loading and tracking
  // --------------------------------------------------------------------------------
  React.useEffect(() => {
    // getFamily
    getFamily(fid);
    // tracking
    const unsubscribe = navigation.addListener('focus', () => {
      const currentPath = window.location.pathname + window.location.search;
      ReactGA.send({hitType: "pageview", page: currentPath, title: "FamilyScreen"});
    });
    return unsubscribe;
  }, [navigation, cc]);


  // Get family and members
  const getFamily = async (fid) => {
    const url = BASE_URL+'php/get_family.php';
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: `fid=${fid}`,
      });
      const rspJson = await response.json();
      if (rspJson.rc === 1 ) {  
        IS_DEBUG && console.log(`[OK] getFamily rc=${rspJson.rc}`);
        setCurrFamily(rspJson.family);
        setMembers(rspJson.member);
      } else {
        console.log(`[ERR] rc=${rspJson.rc} msg=${rspJson.msg}`);
      }
    } catch (error) {
      console.log(`[ERR] error=${error}`);
    }
  }

  // Invite family members
  const inviteFamilyMember = async (fid, email) => {
    IS_DEBUG && console.log('inviteFamilyMember('+fid+','+email+') -> '+guestEmail);
    const url = BASE_URL+'php/invite_family_member.php';
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: `fid=${fid}&referal_email=${email}&family=${currFamily}&guest_email=${guestEmail}`,
      });
      const rspJson = await response.json();
      if (rspJson.rc === 1 ) {  
        IS_DEBUG && console.log(`[OK] inviteFamilyMember rc=${rspJson.rc}`);
        getFamily(fid);
      } else {
        console.log(`[ERR] rc=${rspJson.rc} msg=${rspJson.msg}`);
      }
    } catch (error) {
      console.log(`[ERR] error=${error}`);
    }
  }

  // delete family member
  const deleteFamilyMember = async (uid) => {
    IS_DEBUG && console.log(`Deleting user with uid ${uid}`);
    const url = `${BASE_URL}php/delete_family_member.php`;
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: `uid=${uid}`,
      });
      const rspJson = await response.json();
      if (rspJson.rc === 1 ) {  
        IS_DEBUG && console.log(`[OK] deleteFamilyMember rc=${rspJson.rc}`);
        getFamily(fid);
      } else {
        console.log(`[ERR] rc=${rspJson.rc} msg=${rspJson.msg}`);
      }
    } catch (error) {
      console.log(`[ERR] error=${error}`);
    }
  }

  // activate family member
  const activateFamilyMember = async (uid) => {
    IS_DEBUG && console.log(`Activate user with uid ${uid}`);
    const url = `${BASE_URL}php/activate_family_member.php`;
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: `uid=${uid}`,
      });
      const rspJson = await response.json();
      if (rspJson.rc === 1 ) {  
        IS_DEBUG && console.log(`[OK] activateFamilyMember rc=${rspJson.rc}`);
        getFamily(fid);
      } else {
        console.log(`[ERR] rc=${rspJson.rc} msg=${rspJson.msg}`);
      }
    } catch (error) {
      console.log(`[ERR] error=${error}`);
    }
  }

  
  // activate family member
  const switchFamilyRole = async (uid, roleType) => {
    IS_DEBUG && console.log(`Switch user with uid ${uid} roleType ${roleType}`);
    const url = `${BASE_URL}php/switch_family_role.php`;
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: `uid=${uid}&roleType=${roleType}`,
      });
      const rspJson = await response.json();
      if (rspJson.rc === 1 ) {  
        IS_DEBUG && console.log(`[OK] switchFamilyRole rc=${rspJson.rc}`);
        getFamily(fid);
      } else {
        console.log(`[ERR] rc=${rspJson.rc} msg=${rspJson.msg}`);
      }
    } catch (error) {
      console.log(`[ERR] error=${error}`);
    }
  }

  /**
  * Stateless component:
  * Fill DataTabke.Row with family data
  **/
  const FamilyDataTableRow = (props) => (
    members && members.map(item => {
      return <DataTable.Row key={item.id}>
          <DataTable.Cell>
            {item.email}
          </DataTable.Cell>
          <DataTable.Cell>
            {( item.status == 1 ? item.role_name : 'Inactive' )}
          </DataTable.Cell>
          <DataTable.Cell>
            {( item.status==0 &&
              <>
                <TouchableOpacity onPress={() => activateFamilyMember(item.id)}>
                  <Text>Activate &#187;</Text>
                </TouchableOpacity>
                <TouchableOpacity onPress={() => deleteFamilyMember(item.id)}>
                  <Text>Delete &#187;</Text>
                </TouchableOpacity>
              </>
            )}
            {( item.status==1 && item.role_type!=ROLE_ADULT_ADMIN &&
              <TouchableOpacity onPress={() => switchFamilyRole(item.id, item.role_type)}>
                <Text>Switch &#187;</Text>
              </TouchableOpacity>
            )}
          </DataTable.Cell>
        </DataTable.Row>;
    })
  )

  /**
  * Render
  **/
  return (
    <ScrollView>
      <View style={styles.container}>

        <Image
          source={{uri: BASE_URL+"assets/img/family.png"}}
          style={styles.img} />
        <Text style={styles.text4}>{currFamily} (#{fid})</Text>
        <Text />
        <DataTable>
          <DataTable.Header >
            <DataTable.Title><Text style={styles.text2}>Email</Text></DataTable.Title>
            <DataTable.Title><Text style={styles.text2}>Status</Text></DataTable.Title>
            <DataTable.Title><Text style={styles.text2}>Aktion</Text></DataTable.Title>
          </DataTable.Header>
          <FamilyDataTableRow />
        </DataTable>

        <View style={styles.separator} />

        <Text style={styles.text4}>Familie Verwalten</Text>
        <Text>Lade Mitglieder ein</Text>
        <Text />
        <View style={styles.inputView} >
          <TextInput
            style={styles.inputText}
            placeholder="Email..."
            placeholderTextColor="#003f5c"
            onChangeText={text => setGuestEmail(text)}/>
        </View>
        <TouchableOpacity
          style={styles.btn}
          onPress={() => inviteFamilyMember(fid, email)}
          underlayColor='#fff'>
          <Text style={styles.btnText}>{t.invite}</Text>
        </TouchableOpacity>

        <View style={styles.separator} />
        
        <View style={styles.footer}>
          <Text style={styles.footer_text}>Logged in as {email}</Text>
        </View>

      </View>
    </ScrollView>
  );
}

import React from 'react';
import { StyleSheet, Text, TextInput, TouchableOpacity, View} from 'react-native';
import { BASE_URL, APP_NAME, IS_DEBUG } from '/constants/Config';
import { styles } from '../assets/css/styles';
import ReactGA from 'react-ga4';

//--------------------------------------------------------------------------------
// Export function
//--------------------------------------------------------------------------------
export default function LogoutScreen({ navigation }) {

  React.useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      // tracking
      const currentPath = window.location.pathname + window.location.search;
      ReactGA.send({hitType: "pageview", page: currentPath, title: "LogoutScreen"});
    });
    return unsubscribe;
  }, [navigation]);

  /**
  * Go to home screen - questions
  **/
  const onPressHome = () => {
    navigation.navigate('Question');
  }

/**
  * Delete items from session store and logout.
  * Forward to login screen
  **/
  const onPressLogout = () => {
    try {
      sessionStorage.removeItem('uid');
      sessionStorage.removeItem('fid');
      sessionStorage.removeItem('email');
      // this will navigate to login screen
      navigation.navigate('Question'); 
    } catch (error) {
      console.error('[ERR] logout() error=', error);
    }
  }


  return (
    <View style={styles.container_blue}>
      <Text style={styles.logo}>{APP_NAME}</Text>
      <TouchableOpacity onPress={onPressLogout} style={styles.btn}>
        <Text style={styles.btnText}>LOGOUT</Text>
      </TouchableOpacity>
      <TouchableOpacity onPress={onPressHome}>
        <Text style={styles.btnText}>HOME</Text>
      </TouchableOpacity>
    </View>
  );

}


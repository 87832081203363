import React from 'react';
import 'chart.js/auto';
import { View } from 'react-native';
import { Bar } from 'react-chartjs-2';
import { styles } from '../assets/css/styles';

export const options = {
  plugins: {
    title: {
      display: true,
      text: 'Bar Chart (distribution)',
      font: {
        size: 18,
      },
    },
    legend: {
      labels: {
        font: {
          size: 12,
        },
      },
    },
  },
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      stacked: true,
      ticks: {
        font: {
          size: 12,
        },
      },
    },
    y: {
      stacked: true,
      ticks: {
        font: {
          size: 12,
        },
      },
    },
  },
};
  
const BarChart = ({ vote }) => {
  const data = {
    labels: ['Very Good', 'Good', 'Ok', 'Bad', 'Very Bad'],
    datasets: [
      {
        label: 'Votes',
        backgroundColor: ['#c9e52f', '#a6d75b', '#76c68f', '#48b5c4', '#22a7f0'],
        data: [vote.v5, vote.v4, vote.v3, vote.v2, vote.v1],
      },
    ],
  };

  return ( 
    <View style={styles.chartContainer}>
      <Bar options={options} data={data} />
    </View>
  ); 
}
export default BarChart;


import * as React from 'react';
import { ScrollView, TouchableOpacity, Image } from 'react-native';
import {Picker} from '@react-native-picker/picker';
import * as SecureStore from 'expo-secure-store';

import { BASE_URL, ROLE_ADULT_ADMIN, ROLE_ADULT, SOURCE_DEFAULT, SOURCE_OXFORD, IS_DEBUG } from '../constants/Config';
import { translations } from '../constants/translations';
import { handleCcChange } from '../utils/ChangeHandlers';

import { Text, View } from '../components/Themed';
import { styles } from '../assets/css/styles';
import ReactGA from 'react-ga4';

export default function QuestionScreen({navigation, route /*, cc*/}) {

  // session values
  const uid = sessionStorage.getItem('uid');
  const fid = sessionStorage.getItem('fid');
  const email = sessionStorage.getItem('email');
  const type = sessionStorage.getItem('type');
  // translation
  const [cc, setCc] = React.useState( sessionStorage.getItem('cc') );
  let t = translations[cc] || translations.en;
  // question states
  const [showQuestion, setShowQuestion] = React.useState(true);
  const [isCustomQuestion, setIsCustomQuestion] = React.useState(true);
  const [isOxfordQuestion, setIsOxfordQuestion] = React.useState(false);
  const [question, setQuestion] = React.useState(null);
  const [category, setCategory] = React.useState(null);
  const [remainingSeconds, setRemainingSeconds] = React.useState(null);
  const [remainingDays, setRemainingDays] = React.useState(null);
  // load from DB
  const [qid, setQid] = React.useState(0);
  // selected family memebers
  const [familyKids, setFamilyKids] = React.useState(null); // array of family kids
  const [vid, setVid] = React.useState(uid); // vote for this uid
  

  // --------------------------------------------------------------------------------
  // handle cc changes
  // --------------------------------------------------------------------------------
  React.useEffect(() => {
    const wrappedHandleCcChange = () => handleCcChange(setCc);
    window.addEventListener('ccChange', wrappedHandleCcChange);
    return () => {
      window.removeEventListener('ccChange', wrappedHandleCcChange);
    };
  }, [cc]);

  // --------------------------------------------------------------------------------
  // data loading and tracking
  // --------------------------------------------------------------------------------
  React.useEffect(() => {
    // get question
    IS_DEBUG && console.log(`QuestionScreen useEffect >> getQuestion() uid=${uid} fid=${fid} email=${email} type=${type} cc=${cc}`);
    getQuestion(uid, fid, cc);
    getFamilyKids(fid);
    // tracking
    const unsubscribe = navigation.addListener('focus', () => {  
      const currentPath = window.location.pathname + window.location.search;
      ReactGA.send({hitType: "pageview", page: currentPath, title: "QuestionScreen"});
    });
    return unsubscribe;
  }, [navigation, cc]); 


  //
  const remindLater = () => {
    IS_DEBUG && console.log('remindLater');
  }
  //
  const noAnswer = () => {
    IS_DEBUG && console.log('noAnswer');
  }
  //
  const feedback = (feedback) => {
    IS_DEBUG && console.log('feedback='+feedback);
  }

  // get non adult family members
  const getFamilyKids = async (fid) => {
    const url = BASE_URL+'php/get_family_kids.php';
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: `fid=${fid}`,
      });
      
      const rspJson = await response.json();
      IS_DEBUG && console.log(`getFamilyKids >> rsp=${rspJson} fid=${fid}`);
  
      if (rspJson.rc === 1 ) {  
        setFamilyKids(rspJson.kids);
      } else {
        console.log("[ERR] rc=", rspJson.rc);
      }
    } catch (error) {
      console.error("[ERR] ", error);
    }
  }

  // Get question of the day
  const getQuestion = async (uid, fid, cc) => {
    const url = BASE_URL+'php/get_question.php';
      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: `uid=${uid}&fid=${fid}&cc=${cc}`,
        });
        
        const rspJson = await response.json();
        IS_DEBUG && console.log(`getQuestion(${uid},${fid},${cc})`);
        if (rspJson.rc === 1 ) {  
          // show question
          setShowQuestion(true);
          setQuestion(rspJson.data.question);
          setCategory(rspJson.data.category);
          setQid(rspJson.data.id);
          setIsCustomQuestion(rspJson.data.source===SOURCE_DEFAULT);
          setIsOxfordQuestion(rspJson.data.source===SOURCE_OXFORD);
        } else {
          IS_DEBUG && console.log(rspJson);
          // need to wait for next question
          setShowQuestion(false);
          setQuestion(rspJson.msg);
          setRemainingSeconds(rspJson.remainingSeconds);
          setRemainingDays(rspJson.remainingDays);
        }
      } catch (error) {
        console.error('[ERR] GetQuestion('+uid+','+fid+') url='+url+' error='+error);
      }
  }


  // Vote question
  const onPressVote = async (vote) => {
    const url = BASE_URL+'php/insert_vote.php';
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: `uid=${uid}&fid=${fid}&qid=${qid}&vote=${vote}${vid !== null ? `&vid=${vid}` : ''}`,
      });
      
      const rspJson = await response.json();
      IS_DEBUG && console.log(`onPressVote >> rsp=${rspJson} fid=${uid} period=${fid} qid=${qid} vote=${vote} vid=${vid}`);
  
      if (rspJson.rc === 1 ) {  
        IS_DEBUG && console.log('<< onPressVote rsp.success='+rspJson.success+" rsp.sql="+rspJson.sql+" rsp="+rspJson);
        setQuestion('');
        setQid(0);
        setVid(uid);
        navigation.navigate('Thanks');
      } else {
        console.log("[ERR] rc=", rspJson.rc);
      }
    } catch (error) {
      console.error("[ERR] ", error);
    }
  }

  const renderQuestionCustom = () => {
    return (
      <>
        <TouchableOpacity style={styles.answer} onPress={() => onPressVote(5)}>
          <Text style={styles.answerText}>{t.strongAgree}</Text>
        </TouchableOpacity>
        <TouchableOpacity style={styles.answer} onPress={() => onPressVote(4)}>
          <Text style={styles.answerText}>{t.agree}</Text>
        </TouchableOpacity>
        <TouchableOpacity style={styles.answer} onPress={() => onPressVote(3)}>
          <Text style={styles.answerText}>{t.neutral}</Text>
        </TouchableOpacity>
        <TouchableOpacity style={styles.answer} onPress={() => onPressVote(2)}>
          <Text style={styles.answerText}>{t.disagree}</Text>
        </TouchableOpacity>
        <TouchableOpacity style={styles.answer} onPress={() => onPressVote(1)}>
          <Text style={styles.answerText}>{t.strongDisagree}</Text>
        </TouchableOpacity>
      </>
    );
  };
  const renderQuestionOxford = () => {
    return (
      <>
        <TouchableOpacity style={styles.answer} onPress={() => onPressVote(6)}>
          <Text style={styles.answerText}>{t.absolutely}</Text>
        </TouchableOpacity>
        <TouchableOpacity style={styles.answer} onPress={() => onPressVote(5)}>
          <Text style={styles.answerText}>{t.often}</Text>
        </TouchableOpacity>
        <TouchableOpacity style={styles.answer} onPress={() => onPressVote(4)}>
          <Text style={styles.answerText}>{t.somewhat}</Text>
        </TouchableOpacity>
        <TouchableOpacity style={styles.answer} onPress={() => onPressVote(3)}>
          <Text style={styles.answerText}>{t.partly}</Text>
        </TouchableOpacity>
        <TouchableOpacity style={styles.answer} onPress={() => onPressVote(2)}>
          <Text style={styles.answerText}>{t.oftenNot}</Text>
        </TouchableOpacity>
        <TouchableOpacity style={styles.answer} onPress={() => onPressVote(1)}>
          <Text style={styles.answerText}>{t.absolutelyNot}</Text>
        </TouchableOpacity>
      </>
    );
  };
  

  const renderQuestion = () => {
    return (
      <>
        <Text style={styles.question}>{question}</Text>
        <View style={styles.separator} />
  
        {showQuestion ? renderQuestionOxford() : renderQuestionCustom()}
  
        <View style={styles.separator} />
  
        <Text style={styles.text1}>Category: {category}</Text>
        
        <TouchableOpacity
          style={styles.btn}
          onPress={() => remindLater()}
          underlayColor='#fff'>
          <Text style={styles.btnText}>{t.remindLater}</Text>
        </TouchableOpacity>
  
        {/*
        <TouchableOpacity
          style={styles.btn}
          onPress={() => noAnswer()}
          underlayColor='#fff'>
          <Text style={styles.btnText}>Ich möchte die Frage nicht beantworten</Text>
        </TouchableOpacity>
        */}
  
        <Text>
          <TouchableOpacity onPress={() => feedback(0)}>
            <Image
              source={{ uri: BASE_URL + "assets/img/dislike.png" }}
              style={{ width: 50, height: 50 }}
            />
          </TouchableOpacity>
          <Text> ... {t.feedback} ... </Text>
          <TouchableOpacity onPress={() => feedback(1)}>
            <Image
              source={{ uri: BASE_URL + "assets/img/like.png" }}
              style={{ width: 50, height: 50 }}
            />
          </TouchableOpacity>
        </Text>

      </>
    );
  };
  
  const renderMood = () => {
    return (
      <>
        <Image
          source={{ uri: "https://reactnative.dev/docs/assets/p_cat1.png" }}
          style={{ width: 200, height: 200 }}
        />
        <Text >{t.noQuestion}</Text>
        <Text style={styles.question}>{question}</Text>
  
        <TouchableOpacity style={styles.answer} onPress={() => onPressVote(5)}>
          <Text style={styles.answerText}>{t.moodStrongGood}</Text>
        </TouchableOpacity>
        <TouchableOpacity style={styles.answer} onPress={() => onPressVote(4)}>
          <Text style={styles.answerText}>{t.moodGood}</Text>
        </TouchableOpacity>
        <TouchableOpacity style={styles.answer} onPress={() => onPressVote(3)}>
          <Text style={styles.answerText}>{t.moodNeutral}</Text>
        </TouchableOpacity>
        <TouchableOpacity style={styles.answer} onPress={() => onPressVote(2)}>
          <Text style={styles.answerText}>{t.moodBad}</Text>
        </TouchableOpacity>
        <TouchableOpacity style={styles.answer} onPress={() => onPressVote(1)}>
          <Text style={styles.answerText}>{t.moodStrongBad}</Text>
        </TouchableOpacity>
  
        <View style={styles.separator} />
  
        <Text style={styles.feedback}>
          Next question in {remainingSeconds} seconds and {remainingDays} days
        </Text>
  
        <TouchableOpacity
          style={styles.btn}
          onPress={() => getQuestion(uid, fid, cc)}
          underlayColor='#fff'>
          <Text style={styles.btnText}>Refresh</Text>
        </TouchableOpacity>
      </>
    );
  };

  // Vote in the name of a different family members
  // Adults can just vote themselves.
  // Adults can vote for all non-adults (kids, teenagers)
  const renderFamilyKids = () => {
    return (
      <>
        <Text style={styles.text2}>Vote for</Text>
        <Picker
          selectedValue={vid}
          onValueChange={(vid) => setVid(vid)}
        >
          <Picker.Item label={email} value={uid} />
          {familyKids && familyKids.map((kid) => (
            <Picker.Item key={kid.id} label={kid.email} value={kid.id} />
          ))}
        </Picker>
      </>
    );
  };

  return (
    <ScrollView style={styles.container_scroll}>
      <View style={styles.container}>

        {showQuestion ? renderQuestion() : renderMood()}
  
        <View style={styles.separator} />

        { (type==ROLE_ADULT || type==ROLE_ADULT_ADMIN) ? renderFamilyKids() : ''}

        <View style={styles.separator} />

        <View style={styles.footer}>
          <Text style={styles.footer_text}>Logged in as {email}</Text>
        </View>

      </View>
    </ScrollView>
  );

}




import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({

  /*
  body: {
    backgroundColor: "#fff",
  },
  flexbox: {
    display: 'flex',
    justifyContent: 'flex-start',  // Elements will start at the top
    flexDirection: 'column',
    minHeight: '100vh',
    alignItems: 'center',
  },
  */
  container_scroll: {
    backgroundColor: "#ffffff",
  },

  container: {    
    alignItems: 'center',
    paddingLeft: 20,
    paddingRight: 20,
    justifyContent:"center",
  },
  footer: {
    color: '#003f5c',
    alignItems: 'center',
    paddingLeft: 20,
    paddingRight: 20,
    textAlign: 'center',
  },
  container1024: {
    maxWidth:'1024px',
  },

  question: {
    fontSize: 20,
    fontWeight: 'bold',
    padding: 10,
    margin: 10,
  },
  answer: {
    width: '80%',
    maxWidth: 1080,
    backgroundColor: "#003f5c", // dark blue
    height:50,
    alignItems:"center",
    justifyContent:"center",
    marginTop: 10,
    marginBottom: 10,
    fontSize: 20,
  },
  answerText:{
    color:"white"
  },
  feedback: {
    fontSize: 10,
    padding: 10,
    margin: 10,
  },


  text4: {
    fontSize: 20,
    fontWeight: 'bold',
    padding: 10,
    margin: 10,
  },
  text3: {
    fontSize: 18,
    fontWeight: 'bold',
    padding: 5,
    margin: 5,
  },
  text2: {
    fontSize: 16,
    fontWeight: 'bold',
    padding: 3,
    margin: 3,
  },
  text1: {
    fontSize: 14,
    padding: 2,
    margin: 2,
  },
  text0: {
    fontSize: 12,
    padding: 1,
    margin: 1,
  },

  feedback_pink: {
    fontSize: 12,
    color: '#fb5b5a',
  },

  separator: {
    width: '80%',
    maxWidth: 1080,
    marginVertical: 10,
    height: 1,
    backgroundColor: '#EAF2F8',
  },
  space: {
    height: 50,
    width: 200,
  },

  
  footer_text:{
    color:"#003f5c",
    "&:hover": {
      color: "fb5b5a", //pink
    },
  },

  centeredContent: {
    alignItems: 'center', // Center items horizontally
    justifyContent: 'center', // Center items vertically
  },
  
  /************ report ************/
  // ["#115f9a", "#1984c5", "#22a7f0", "#48b5c4", "#76c68f", "#a6d75b", "#c9e52f", "#d0ee11", "#d0f400"]
  // https://www.heavy.ai/blog/12-color-palettes-for-telling-better-stories-with-your-data
  level8_box: {
    width: 10,height: 10,
    backgroundColor: '#d0f400', // green
  },
  level7_box: {
    width: 10,height: 10,
    backgroundColor: '#d0ee11',
  },
  level6_box: {
    width: 10,height: 10,
    backgroundColor: '#c9e52f',
  },
  level5_box: {
    width: 10,height: 10,
    backgroundColor: '#a6d75b',
  },
  level4_box: {
    width: 10,height: 10,
    backgroundColor: '#76c68f',
  },
  level3_box: {
    width: 10,height: 10,
    backgroundColor: '#48b5c4',
  },
  level2_box: {
    width: 10,height: 10,
    backgroundColor: '#22a7f0',
  },
  level1_box: {
    width: 10,height: 10,
    backgroundColor: '#1984c5', 
  },
  level0_box: {
    width: 10,height: 10,
    backgroundColor: '#115f9a', //blue
  },
  /************ use for oxford report ************/

  level8: {
    backgroundColor: '#d0f400', // green
  },
  level7: {
    backgroundColor: '#d0ee11',
  },
  level6: {
    backgroundColor: '#c9e52f',
  },
  level5: {
    backgroundColor: '#a6d75b',
  },
  level4: {
    backgroundColor: '#76c68f',
  },
  level3: {
    backgroundColor: '#48b5c4',
  },
  level2: {
    backgroundColor: '#22a7f0',
  },
  level1: {
    backgroundColor: '#1984c5', 
  },
  level0: {
    backgroundColor: '#115f9a', //blue
  },
  
  /****** Input, Button ******/

  inputView:{
    width:"80%",
    maxWidth: 1080,
    backgroundColor:"#465881",
    borderRadius:25,
    height:50,
    marginBottom:20,
    justifyContent:"center",
    padding:20
  },
  inputMultiView:{
    width:"80%",
    maxWidth: 1080,
    backgroundColor:"#465881",
    borderRadius:25,
    height:200,
    marginBottom:20,
    justifyContent:"center",
    padding:20
  },
  inputText:{
    height:50,
    color:"white"
  },
  inputMultiText:{
    height:200,
    color:"white"
  },
  btn:{
    width:"80%",
    maxWidth: 1080,
    backgroundColor:"#fb5b5a",
    borderRadius:25,
    height:50,
    alignItems:"center",
    justifyContent:"center",
    marginTop:10,
    marginBottom:10
  },
  btnText:{
    color:"white"
  },
  chart80:{
    width:"80%",
    maxWidth: 1080,
  },

/************/
  img: {
    margin:25,
    width: 150,
    height: 150
  },


  // login screen
  container_blue: {
    flex: 1,
    backgroundColor: '#003f5c',
    alignItems: 'center',
    justifyContent: 'center',
  },

  logo:{
    fontWeight:"bold",
    fontSize:50,
    color:"#fb5b5a",
    marginBottom:40
  },

  forgot:{
    color:"white",
    fontSize:11
  },

  placeholder:{
    marginTop:40,
  },

   /* Switch label */ 
   switchContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 10,
  },
  switchLabel: {
    fontSize: 16,
    padding: 10,
  },

  // chart container
  chartContainer: {
    width: '80%',
    maxWidth: '1080px',
    height: 'auto',
    minHeight: '256px',
    maxHeight: '512px',
  },
  '@media only screen and (max-width: 768px)': {
    chartContainer: {
      width: '90%',
      minHeight: '300px',
      maxHeight: '500px',
    },
  },
  '@media only screen and (max-width: 480px)': {
    chartContainer: {
      width: '100%',
      minHeight: '250px',
      maxHeight: '400px',
    },
  },

});

// we can have values between [1,5]
export const getColorBox = (idx) => {
  if (idx == 5)      return styles.level5_box;
  else if (idx >= 4) return styles.level4_box;
  else if(idx >= 3 ) return styles.level3_box;
  else if(idx >= 2 ) return styles.level2_box;
  else if(idx > 1 )  return styles.level1_box;
  else               return styles.level0_box;
};

// we can have values between [0,6]
export const getColorStyle = (idx) => {
  if (idx == 6) return styles.level6;
  else if (idx >= 5) return styles.level5;
  else if (idx >= 4) return styles.level4;
  else if (idx >= 3) return styles.level3;
  else if (idx >= 2) return styles.level2;
  else if (idx > 1)  return styles.level1;
  else               return styles.level0;
};

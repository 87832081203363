import * as React from 'react';
import { StyleSheet, ScrollView } from 'react-native';

import { BASE_URL, IS_DEBUG } from '../constants/Config';
import { translations } from '../constants/translations';

import { Text, View } from '../components/Themed';
import { styles, getColorBox } from '../assets/css/styles';
import { handleCcChange } from '../utils/ChangeHandlers';
import BarChart from '../utils/BarChart';
import LineChart from '../utils/LineChart';
import ReactGA from 'react-ga4';

interface Question {
    question: string;
    category: string;
    period: string;
    vote: Vote;
}
interface Vote {
  v1: number;
  v2: number;
  v3: number;
  v4: number;
  v5: number;
  votes: number;
  index: number;
  percent: number;
}

//------------------------------------------------------------------------------
// Main  
//------------------------------------------------------------------------------
export default function ReportQuestionScreen({navigation, route}) {

  // session
  const uid = sessionStorage.getItem('uid');
  const fid = sessionStorage.getItem('fid');
  const email = sessionStorage.getItem('email');
  // translation
  const [cc, setCc] = React.useState( sessionStorage.getItem('cc') );
  let t = translations[cc] || translations.en;
  // states
  const [qid, setQid] = React.useState(1); // Question Id
  const [question, setQuestion] = React.useState<Question | null>(null);
  const [period, setPeriod] = React.useState('week');

  // --------------------------------------------------------------------------------
  // handle cc changes
  // --------------------------------------------------------------------------------
  React.useEffect(() => {
    const wrappedHandleCcChange = () => handleCcChange(setCc);
    window.addEventListener('ccChange', wrappedHandleCcChange);
    return () => {
      window.removeEventListener('ccChange', wrappedHandleCcChange);
    };
  }, [cc]);

  // --------------------------------------------------------------------------------
  // data loading and tracking
  // --------------------------------------------------------------------------------
  React.useEffect(() => {
    // getQuestionDetails
    IS_DEBUG && console.log(`getQuestionDetails(${fid}, ${period}, ${qid}) route=${route.params.qid}`);
    setQid(route.params.qid);
    setPeriod(route.params.period);
    getQuestionDetails(fid, route.params.period, route.params.qid, cc);
    // tracking
    const unsubscribe = navigation.addListener('focus', () => {
      const currentPath = window.location.pathname + window.location.search;
      ReactGA.send({hitType: "pageview", page: currentPath, title: "ReportQuestionScreen"});
    });
    return unsubscribe;
  }, [navigation, cc]);

  
  
  const getQuestionDetails = async (fid, period, qid, cc) => {
    const url = `${BASE_URL}php/get_report_question.php`;
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: `fid=${fid}&period=${period}&qid=${qid}&cc=${cc}`,
      });
      
      const rspJson = await response.json();
      IS_DEBUG && console.log(`get_report_question >> rsp=${rspJson} fid=${fid} period=${period} qid=${qid} cc=${cc}`);
  
      if (rspJson.rc === 1 ) {  
        if ( rspJson ) setQuestion(rspJson);
        /*
        const dates = history.map((entry) => entry.vote_date);
        const averageMoods = history.map((entry) => parseFloat(entry.average_mood));
        const numVotes = history.map((entry) => parseInt(entry.num_votes));
        */
      } else {
        console.log("[ERR] rc=", rspJson.rc);
      }
    } catch (error) {
      console.error("[ERR] ",error);
    }
  }

  let view = (
    <ScrollView>
      <View style={styles.container}>

        {question && (
          <React.Fragment>
            <Text style={styles.text4}>{question.question}</Text>
            <Text>{t.category}: {question.category}</Text>
            <Text>{t.evaluationPeriod}: {period}</Text>

            <View style={styles.separator} />
            <Text style={styles.text3}>{t.overallDistribution}</Text>
            <Text style={styles.text2}>
              <View style={getColorBox(question.vote.index)}></View> 
              {question.vote.percent}% Positiv ({question.vote.index} / 5)
            </Text>
            <BarChart vote={question.vote} />
            <Text style={styles.text1}>
              {question.vote.votes} total votes
            </Text>

            <View style={styles.separator} />
            <Text style={styles.text3}>Zeitverlauf</Text>
            <LineChart history={question.history.data} />
            <Text style={styles.text1}>
              {question.history.votes} total votes
            </Text>

          </React.Fragment>
        )}

        <View style={styles.footer}>
          <Text style={styles.footer_text}>Logged in as {email}</Text>
        </View>

      </View>
    </ScrollView>
  );

  return view;
}

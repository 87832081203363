import * as React from 'react';
import { StyleSheet, Image } from 'react-native';
import { BASE_URL, IS_DEBUG } from '../constants/Config';
import { translations } from '../constants/translations';
import { Text, View } from '../components/Themed';
import { handleCcChange } from '../utils/ChangeHandlers';

import AdComponent from '../components/AdComponent';

import { styles } from '../assets/css/styles';
import { Dimensions } from 'react-native';
import ReactGA from 'react-ga4';

export default function ThanksScreen({ navigation }) {

  const { width, height } = Dimensions.get('window');

  // translation
  const [cc, setCc] = React.useState( sessionStorage.getItem('cc') );
  let t = translations[cc] || translations.en;

  // --------------------------------------------------------------------------------
  // handle cc changes
  // --------------------------------------------------------------------------------
  React.useEffect(() => {
    const wrappedHandleCcChange = () => handleCcChange(setCc);
    window.addEventListener('ccChange', wrappedHandleCcChange);
    return () => {
      window.removeEventListener('ccChange', wrappedHandleCcChange);
    };
  }, [cc]);

  // --------------------------------------------------------------------------------
  // tracking
  // --------------------------------------------------------------------------------
  React.useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      const currentPath = window.location.pathname + window.location.search;
      ReactGA.send({hitType: "pageview", page: currentPath, title: "ThanksScreen"});
    });
    return unsubscribe;
  }, [navigation]);
  
  return (
      <View style={styles.container}>
        

        <View style={{ position: 'relative', width: width, height: height, justifyContent: 'center', alignItems: 'center' }}>
        {/*<View style={{ position: 'relative', width: width, height: height }}>*/}

          {/* Confetti background */}
          <Image
            source={{ uri: BASE_URL + "assets/img/confetti.gif" }}
            style={{ width: width, height: height, position: 'absolute' }}
          />

          {/* Other elements */}
          <View>
            
            <Image
              source={{ uri: BASE_URL + "assets/img/thank-you.png" }}
              style={{ width: 200, height: 200 }}
            />
            
            <Text>{t.thanks}</Text>
            
            <AdComponent />

          </View>
        </View>

        {/* 
        <AdsAdsense />
        */}
      </View>
  );

}



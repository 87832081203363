import * as React from 'react';
import { BASE_URL, IS_DEBUG } from '../constants/Config';
import { translations } from '../constants/translations';
import { handleCcChange } from '../utils/ChangeHandlers';
import { ScrollView, TextInput, Image, Picker, TouchableOpacity, View, Text} from 'react-native';
import { styles } from '../assets/css/styles';
import ReactGA from 'react-ga4';

interface User {
  id: number;
  email: string;
  mobile: string;
  firstname: string;
  lastname: string;
  type: number;
  born_at: string;
  registered_at: string;
}

export default function ProfileScreen({ navigation, route }) {

  // session
  const email = sessionStorage.getItem('email');
  // translation
  const [cc, setCc] = React.useState( sessionStorage.getItem('cc') );
  let t = translations[cc] || translations.en;
  // profile
  const [user, setUser] = React.useState<User | null>(null);
  const [firstname, setFirstname] = React.useState("");
  const [lastname, setLastname] = React.useState("");
  const [mobile, setMobile] = React.useState("");
  const [bornAt, setBornAt] = React.useState("");
  const [type, setType] = React.useState(0);
  // for date picker (born_at)
  const currYear = 2021;
  const minYear = 1920;


  // --------------------------------------------------------------------------------
  // handle cc changes
  // --------------------------------------------------------------------------------
  React.useEffect(() => {
    const wrappedHandleCcChange = () => handleCcChange(setCc);
    window.addEventListener('ccChange', wrappedHandleCcChange);
    return () => {
      window.removeEventListener('ccChange', wrappedHandleCcChange);
    };
  }, [cc]);

  // --------------------------------------------------------------------------------
  // data loading and tracking
  // --------------------------------------------------------------------------------
  React.useEffect(() => {
    // getUser
    getUser(email);
    // tracking
    const unsubscribe = navigation.addListener('focus', () => {
      const currentPath = window.location.pathname + window.location.search;
      ReactGA.send({hitType: "pageview", page: currentPath, title: "ProfileScreen"});
    });
    return unsubscribe;
  }, [navigation, cc]);
  
  
  // Get user profile
  const getUser = async () => {
    const url = BASE_URL+'php/get_user.php';
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: `email=${email}`,
      });
      const rspJson = await response.json();
      if (rspJson.rc === 1 ) {  
        IS_DEBUG && console.log(`[OK] getUser rc=${rspJson.rc}`);
        setUser(rspJson);
      } else {
        console.log(`[ERR] rc=${rspJson.rc} msg=${rspJson.msg}`);
      }
    } catch (error) {
      console.log(`[ERR] error=${error}`);
    }
  }

  // Update user profile
  const updateUser = async () => {
    const url = BASE_URL+'php/update_user.php';
    IS_DEBUG && console.log(`update_user.php?uid=${user?.id}&firstname=${firstname}&lastname=${lastname}&mobile=${mobile}&born_at=${bornAt}`);
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body:  `uid=${user?.id}&firstname=${firstname}&lastname=${lastname}&mobile=${mobile}&born_at=${bornAt}`
      });
      const rspJson = await response.json();
      if (rspJson.rc === 1 ) {  
        IS_DEBUG && console.log(`[OK] updateUser rc=${rspJson.rc}`);
      } else {
        console.log(`[ERR] rc=${rspJson.rc} msg=${rspJson.msg}`);
      }
    } catch (error) {
      console.log(`[ERR] error=${error}`);
    }
  }

  /**
  * Stateless component:
  * Picker with all birthday years
  **/
  const years = ['2021', '2020', '2019'];
  const BirthdayPickerItem = (props) => (
    years.map(y => {
      return <Picker.Item label={y} value={y} />;
    })
  )

  return (
    <ScrollView style={styles.container_scroll}>
      <View style={styles.container}>

        <Image
          source={{uri: BASE_URL+"assets/img/user.png"}}
          style={styles.img} />
        <Text style={styles.text4}>{t.profile}</Text>
        <View style={styles.inputView} >
          <TextInput
            style={styles.inputText}
            placeholder="Email..."
            placeholderTextColor="#003f5c"
            editable = {false}
            defaultValue={email}/>
        </View>
        <View style={styles.inputView} >
          <TextInput
            style={styles.inputText}
            placeholder="Vorname..."
            placeholderTextColor="#003f5c"
            defaultValue={user?.firstname || ''}
            onChangeText={firstname => setFirstname(firstname)}/>
        </View>
        <View style={styles.inputView} >
          <TextInput
            style={styles.inputText}
            placeholder="Nachname..."
            placeholderTextColor="#003f5c"
            defaultValue={user?.lastname || ''}
            onChangeText={lastname => setLastname(lastname)}/>
        </View>
        <View style={styles.inputView} >
          <TextInput
            style={styles.inputText}
            placeholder="Mobiltelefon..."
            placeholderTextColor="#003f5c"
            defaultValue={user?.mobile || ''}
            onChangeText={mobile => setMobile(mobile)}/>
        </View>
        <View style={styles.inputView} >
          <TextInput
            style={styles.inputText}
            placeholder="Geburtstag (Y-m-d)..."
            placeholderTextColor="#003f5c"
            defaultValue={user?.born_at || ''}
            onChangeText={bornAt => setBornAt(bornAt)}/>
        </View>

        <TouchableOpacity style={styles.btn} onPress={() => updateUser()}>
          <Text style={styles.btnText}>{t.save}</Text>
        </TouchableOpacity>

        <View style={styles.separator} />

        <TouchableOpacity style={styles.btn} onPress={() => navigation.navigate('Family')}>
          <Text style={styles.btnText}>Manage Family</Text>
        </TouchableOpacity>

        <View style={styles.separator} />

        <Text>Role: {user?.type}</Text>
        
        <Text>Registered on {user?.registered_at || ''}</Text>

        <View style={styles.separator} />

        <View style={styles.footer}>
          <Text style={styles.footer_text}>Logged in as {email}</Text>
        </View>

      </View>
    </ScrollView>
  );
}




export const translations = {
    de: {
      // question
      strongAgree: 'Starke Zustimmung',
      agree: 'Zustimmung',
      neutral: 'Stimme nicht zu, aber auch nicht dagegen',
      disagree: 'Widerspruch',
      strongDisagree: 'Starker Widerspruch',
      // oxford
      absolutely: 'Stimmt absolut',
      often: 'Stimmt häufig',
      somewhat: 'Stimmt ein wenig',
      partly: 'Stimmt teilweise nicht',
      oftenNot: 'Stimmt häufig nicht',
      absolutelyNot: 'Stimmt absolut nicht',
      oxfordAnalysisTitle: 'Die Auswertung der Ergebnisse',
      oxfordLevel0: 'Nicht glücklich. Wenn du ehrlich geantwortet hast und dadurch diese sehr geringe Punktzahl erhalten hast, ist es sehr wahrscheinlich, dass du dich und deine Situation schlimmer siehst, als sie ist. Hier könnte das Aufsuchen eines Experten ein guter Rat sein.',
      oxfordLevel1: 'Du bist etwas unglücklich. Mit Achtsamkeit, Meditation, speziellen Achtsamkeits-Kursen, Büchern und Übungen lässt sich dein Glücksempfinden sicher steigern.',
      oxfordLevel2: 'Nicht besonders glücklich oder unglücklich. Ein Wert von 3,5 wäre der exakte numerische Durchschnitt von glücklichen und unglücklichen Lebenssituationen.',
      oxfordLevel3: 'Nicht besonders glücklich oder mäßig glücklich. Vermutlich eine grundsätzliche, allgemeine Zufriedenheit. Das ist der durchschnittliche Wert der meisten befragten Personen.',
      oxfordLevel4: 'Du bist tendenziell glücklich bis ziemlich glücklich. Es gibt immer noch Verbesserungspotential für dich. Du befindest dich bereits auf einem gesunden und glücklichen Weg, deine Glückskurve lässt sich vermutlich mit geringem Aufwand steigern.',
      oxfordLevel5: 'Du bist sehr glücklich. Glücklich zu sein hat weit mehr Vorteile als nur ein gutes Gefühl. Es korreliert mit Gesundheit, besseren Beziehung und der Erreichung deiner Ziele.',
      oxfordLevel6: 'Vermutlich bist du zu glücklich. Neueste Erkenntnisse aus der Glücksforschung deuten darauf hin, dass es ein optimales Maß an Glücksempfinden für das Erreichen unserer Ziele in Bezug auf Arbeit, Schule, Sport oder Gesundheit förderliche Maßnahmen gibt. „Zu glücklich“ kann mit einem niedrigeren Niveau von solchen Dingen in Verbindung gebracht werden.',
      // mood
      moodStrongGood: 'Sehr Gut',
      moodGood: 'Gut',
      moodNeutral: 'Geht so',
      moodBad: 'Schlecht',
      moodStrongBad: 'Sehr schlecht',
      // general
      remindLater: 'Erinnere mich später',
      feedback: 'Diese Frage finde ich',
      thanks: 'Danke fuer deine Stimme!',
      profile: 'Dein Profil',
      save: 'Speichern',
      noQuestion: 'Aktuell gibt es keine Frage.',
      category: 'Kategorie',
      evaluationPeriod: 'Auswertungszeitraum',
      overallDistribution: 'Gesamtverteilung',
      invite: 'Einladen',
      // report
      reportSatisfactionAnalysis: 'Zufriedenheitsanalyse',
      reportMoodIndex: 'Stimmungsindex',
      reportMoodOverTime: 'Stimmung Zeitverlauf',
      reportQuestionIndex: 'Frageindex',
      reportTopQuestions: 'Deine Top Fragen',
      reportBottomQuestions: 'Deine Bottom Fragen',
      reportExplanationTitle: 'Erklärung',
      reportExplanation: 'Der Bericht beschreibt die aktuelle Stimmung in der Familie und wie einzelne Fragen beantwortet wurden. Dabei liegt der Schwerpunkt auf den Top- und Bottom-Fragen. Sie können aber auch alle Ergebnisse der Fragen im Detail anschauen, sowie die Aggregierung aufheben, falls dies gewünscht ist.',
      scoreNote: 'Hinweis: Nicht alle levels werden im jeden Test benutzt',
      // assistant (ai)
      aiWelcomeMsg: 'Wie kann ich helfen',
      aiNote: "HINWEIS: Der Familienassistent befindet sich im Prototypmodus und wird noch entwickelt",
      aiInfo: "Wir verwenden Open-Source-Modelle für große Sprachmodelle (LLM), die vortrainiert und für Ihre Familie angepasst sind.",    
    },
    en: {
      // question
      strongAgree: 'Strongly Agree',
      agree: 'Agree',
      neutral: 'Neither Agree nor Disagree',
      disagree: 'Disagree',
      strongDisagree: 'Strongly Disagree',
      // oxford
      absolutely: 'Absolutely True',
      often: 'Often True',
      somewhat: 'Somewhat True',
      partly: 'Partly True',
      oftenNot: 'Often Not True',
      absolutelyNot: 'Absolutely Not True',
      oxfordAnalysisTitle: 'Analysis of Results',
      oxfordLevel0: 'Not happy. If you answered honestly and received this very low score, it is very likely that you see yourself and your situation worse than it is. Seeking a professional might be a good advice here.',
      oxfordLevel1: 'You are somewhat unhappy. With mindfulness, meditation, special mindfulness courses, books, and exercises, your sense of happiness can surely be improved.',
      oxfordLevel2: 'Not particularly happy or unhappy. A score of 3.5 would be the exact numerical average of happy and unhappy life situations.',
      oxfordLevel3: 'Not particularly happy or moderately happy. Probably a fundamental, general contentment. This is the average score of most surveyed individuals.',
      oxfordLevel4: 'You are tendentially happy to quite happy. There is still room for improvement for you. You are already on a healthy and happy path; your happiness curve can likely be increased with little effort.',
      oxfordLevel5: 'You are very happy. Being happy has far more benefits than just feeling good. It correlates with health, better relationships, and achieving your goals.',
      oxfordLevel6: 'You are probably too happy. Latest findings in happiness research suggest that there is an optimal level of happiness for achieving our goals in terms of work, school, sports, or health-promoting measures. "Too happy" can be associated with a lower level of such things.',
      // mood
      moodStrongGood: 'Very good',
      moodGood: 'Good',
      moodNeutral: 'Ok',
      moodBad: 'Bad',
      moodStrongBad: 'Very bad',
      // general
      remindLater: 'Remind Me Later',
      feedback: 'I find this question',
      thanks: 'Thanks for your vote!',
      profile: 'Your Profile',
      save: 'Save',
      noQuestion: 'Currently no question.',
      category: 'Categorie',
      evaluationPeriod: 'Evaluation period',
      overallDistribution: 'Overall distribution',
      invite: 'Invite',
      // report
      reportSatisfactionAnalysis: 'Satisfaction Analysis',
      reportMoodIndex: 'Mood Index',
      reportMoodOverTime: 'Mood Over Time',
      reportQuestionIndex: 'Question Index',
      reportTopQuestions: 'Your Top Questions',
      reportBottomQuestions: 'Your Bottom Questions',
      reportExplanationTitle: 'Explanation',
      reportExplanation: 'The report describes the current mood in the family and how individual questions were answered. The focus is on the top and bottom questions. However, you can also view all question results in detail and lift the aggregation if desired.',
      scoreNote: 'Note: Not all levels are used in every test',
      // assistant (ai)
      aiWelcomeMsg: 'How can I help',
      aiNote: 'NOTE: The Family Assistant is in prototype mode and under development',
      aiInfo: 'We are using open source large language models (LLM) which are pre-trained and customized for your Family.',
    }
  };
import React from 'react';

import useCachedResources from './hooks/useCachedResources';
import useColorScheme from './hooks/useColorScheme';
import Navigation from './navigation';

import { StatusBar } from 'expo-status-bar';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { IS_DEBUG } from './constants/Config';

// Initialize i18n for multilanguag apps
//import i18n from './i18n';
import i18n from "i18next";
//import { useTranslation } from "react-i18next";
import { initReactI18next } from "react-i18next";
import { I18nextProvider } from 'react-i18next';
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      en: {
        translation: {
          "greeting": "Welcome to React and react-i18next"
        }
      }
    },
    lng: "en", // if you're using a language detector, do not define the lng option
    fallbackLng: "en",
    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  });
  

// google analytics
import ReactGA from 'react-ga4';
//import { useFocusEffect } from '@react-navigation/native';
// Initialize Google Analytics (GA4)
//const TRACKING_ID = "G-1449XN6WLG"; // www.familygeek.ai
//const TRACKING_ID = "S68-jF4DQkO3jHSQsq72ug"; // iOS ?
const TRACKING_ID = "G-RW8TZ8KP1D"; // app.familygeek.ai
ReactGA.initialize(TRACKING_ID);

export default function App() {
  IS_DEBUG && console.log("App");
  const isLoadingComplete = useCachedResources();
  const colorScheme = useColorScheme();
  
  // Multilingual App
  //const { t } = useTranslation();

  // Hook to track screen views when a screen becomes focused
  React.useEffect(() => {
    const currentPath = window.location.pathname + window.location.search;
    ReactGA.send({hitType: "pageview", page: currentPath, title: "Init App" });
  }, []);  

  if (!isLoadingComplete) {
    return null;
  } else {
    IS_DEBUG && console.log("App: load navigation");
    return (
        /* <I18nextProvider i18n={i18n}> */
          <SafeAreaProvider>
            <Navigation colorScheme={colorScheme} />
            {/* <h2>{t('Welcome to React')}</h2> */}
            <StatusBar />
          </SafeAreaProvider>
        /* </I18nextProvider> */
    );
  }
}

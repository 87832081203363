import React from 'react';
import { StyleSheet, Text, TextInput, TouchableOpacity, View } from 'react-native';
import { BASE_URL, APP_NAME, IS_DEBUG } from '/constants/Config';
import { styles } from '../assets/css/styles';
import ReactGA from 'react-ga4';

//--------------------------------------------------------------------------------
// Login function which checks database with username and passwd
//--------------------------------------------------------------------------------
const loginUser = async (email:string, password:string) => {
  const loginURL = BASE_URL+'php/login.php';
  IS_DEBUG && console.log(">> Login  >> email="+email+" password="+password+" base+url="+BASE_URL);

  try {
    const response = await fetch(loginURL, {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: `email=${email}&password=${password}`,
    });

    const jsonResponse = await response.json();

    if (jsonResponse.rc === 0) {
      const uid = jsonResponse.id;
      const fid = jsonResponse.fid_default;
      const type = jsonResponse.type;
      const cc = jsonResponse.cc;
      IS_DEBUG && console.log(`Login << uid=${uid} fid=${fid} type=${type} cc=${cc}`);
      return { success: true, uid:uid, fid:fid, type:type, cc:cc };
    } else {
      IS_DEBUG && console.log(`[ERR] Login failed jsonResponse=${jsonResponse} `);
      return { success: false, message: jsonResponse.msg };
    }

  } catch (error) {
    IS_DEBUG && console.error('[ERR] Login error:', error);
    return { success: false, message: 'An error occurred during login.' };
  }
}


//--------------------------------------------------------------------------------
// Export function
//--------------------------------------------------------------------------------
export default function LoginScreen({ navigation }) {

  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [info, setInfo] = React.useState('');

  React.useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      // tracking
      const currentPath = window.location.pathname + window.location.search;
      ReactGA.send({hitType: "pageview", page: currentPath, title: "LoginScreen"});
    });
    return unsubscribe;
  }, [navigation]);

  // login and navigate to question screen
  const onPressLogin = async () => {
    const loginResult = await loginUser(email, password);
    if (loginResult.success) {
      const { uid, fid, type, cc } = loginResult;
      sessionStorage.setItem('uid', uid);
      sessionStorage.setItem('fid', fid);
      sessionStorage.setItem('email', email);
      sessionStorage.setItem('type', type);
      sessionStorage.setItem('cc', cc);
      IS_DEBUG && console.log(`onPressLogin >> navigate.Question uid=${uid} fid=${fid} email=${email} type=${type} cc=${cc}`);
      // automatically logs in afterwards as defined in BottomTabNavigator
      //navigation.navigate('Question', { uid:2, fid:3, email:'test'});
      navigation.navigate('Login');
    } else {
      IS_DEBUG && console.log(`[ERR] Login failed: ${loginResult.message} `);
      setInfo(`Login failed. ${loginResult.message}`);
    }
  };

  // go to register screen
  const onPressRegisterScreen = async () => {
    navigation.navigate('Register');
  }

  return (
    <View style={styles.container_blue}>
      
      <Text style={styles.logo}>{APP_NAME}</Text>
      <View style={styles.inputView} >
        <TextInput
          style={styles.inputText}
          autoCapitalize="none"
          placeholder="Email..."
          placeholderTextColor="#003f5c"
          onChangeText={text => setEmail(text)}
          onSubmitEditing={onPressLogin} // Enter
          />
      </View>
      <View style={styles.inputView} >
        <TextInput
          secureTextEntry
          style={styles.inputText}
          placeholder="Password..."
          placeholderTextColor="#003f5c"
          onChangeText={text => setPassword(text)}
          onSubmitEditing={onPressLogin} // Enter
          />
      </View>
      <TouchableOpacity>
        <Text style={styles.forgot}>Forgot Password?</Text>
      </TouchableOpacity>
      <Text style={styles.placeholder}>
      </Text>
      <TouchableOpacity onPress={onPressLogin} style={styles.btn}>
        <Text style={styles.btnText}>LOGIN</Text>
      </TouchableOpacity>
      <TouchableOpacity onPress={onPressRegisterScreen} >
        <Text style={styles.btnText}>Signup</Text>
      </TouchableOpacity>
      {info && 
        <>
          <Text style={styles.placeholder}></Text>
          <Text style={styles.feedback_pink}>{info}</Text>
        </>
      }
      
    </View>
  );

}


//import Constants from 'expo-constants';
//export const BASE_URL = Constants.manifest.extra.baseURL;;

// DEV = http://familygeek.ai.test/
// PRD = https://app.familygeek.ai/
export const BASE_URL = 'https://app.familygeek.ai/';
export const IS_DEBUG = false;

// Name
export const APP_NAME = "FamilyGeek";

// SOURCE
export const SOURCE_DEFAULT = 1;
export const SOURCE_OXFORD = 2;

// Roles
export const ROLE_ANONYMOUS = 1;
export const ROLE_ADULT = 2;
export const ROLE_KID = 3;
export const ROLE_ADULT_ADMIN = 99;

// User confirmed by
export const USER_INACTIVE = 0;
export const USER_CONFIRMED_EMAIL = 1;
export const USER_CONFIRMED_FAMILY = 2;

// OXFORD
export const SOURCE_OXFORD_NUM_QUESTIONS = 29;

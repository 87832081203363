import * as React from 'react';
import { TouchableOpacity, ScrollView } from 'react-native';

import { BASE_URL, IS_DEBUG } from '../constants/Config';
import { translations } from '../constants/translations';
import { Text, View } from '../components/Themed';
import { styles, getColorStyle } from '../assets/css/styles';
import { handleCcChange } from '../utils/ChangeHandlers';
import { DataTable } from 'react-native-paper';

import BarChart from '../utils/BarChart';
import LineChart from '../utils/LineChart';
import ReactGA from 'react-ga4';

//------------------------------------------------------------------------------
// Main  
//------------------------------------------------------------------------------
export default function ReportOxfordDetailsScreen({navigation, route}) {

  // session
  const uid = sessionStorage.getItem('uid');
  const fid = sessionStorage.getItem('fid');
  const email = sessionStorage.getItem('email');
  // translation
  const [cc, setCc] = React.useState( sessionStorage.getItem('cc') );
  let t = translations[cc] || translations.en;
  // states
  const [reportId, setReportId] = React.useState(0);
  const [report, setReport] = React.useState<Report | null>(null);

  // --------------------------------------------------------------------------------
  // handle cc changes
  // --------------------------------------------------------------------------------
  React.useEffect(() => {
    const wrappedHandleCcChange = () => handleCcChange(setCc);
    window.addEventListener('ccChange', wrappedHandleCcChange);
    return () => {
      window.removeEventListener('ccChange', wrappedHandleCcChange);
    };
  }, [cc]);

  // --------------------------------------------------------------------------------
  // data loading and tracking
  // --------------------------------------------------------------------------------
  React.useEffect(() => {
    // getOcfordReport
    setReportId(route.params.rid);
    getOxfordReport(route.params.rid, cc);
    // tracking
    const unsubscribe = navigation.addListener('focus', () => {
        const currentPath = window.location.pathname + window.location.search;
        ReactGA.send({hitType: "pageview", page: currentPath, title: "ReportOxfordDetailsScreen"});
    });
    return unsubscribe;
  }, [navigation, cc]);

  
  const getOxfordReport = async (rid, cc) => {
    const url = `${BASE_URL}php/get_report_oxford.php`;
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: `rid=${rid}&cc=${cc}`,
      });
      
      const rspJson = await response.json();
      IS_DEBUG && console.log(`get_report_oxford >> rsp=${rspJson} rid=${rid} cc=${cc}`);
  
      if (rspJson.rc === 1 ) {  
        setReport(rspJson.report);
      } else {
        console.log("[ERR] rc=", rspJson.rc);
      }
    } catch (error) {
      console.error("[ERR] ",error);
    }
  }

  const renderOxfordAnswer = (vote,score) => {
    const combinedStyle = [styles.text1, getColorStyle(score)];
    if(vote >= 6)       return (<Text style={combinedStyle}>{t.absolutely}</Text>);
    else if (vote >= 5) return (<Text style={combinedStyle}>{t.often}</Text>);
    else if (vote >= 4) return (<Text style={combinedStyle}>{t.somewhat}</Text>);
    else if (vote >= 3) return (<Text style={combinedStyle}>{t.partly}</Text>);
    else if (vote >= 2) return (<Text style={combinedStyle}>{t.oftenNot}</Text>);
    else return (<Text style={combinedStyle}>{t.absolutelyNot}</Text>);
  };

  let view = (
    <ScrollView>
        <View style={styles.container}>

        <Text style={styles.text4}>Oxford Report</Text>
        <Text style={styles.text1}>Starting with high score going to lower score at the bottom</Text>

        <View style={styles.separator} />

        {report && report.map((r, index) => (
        <React.Fragment key={index}>
            <Text style={styles.text2}>{r.question}</Text>
            {renderOxfordAnswer(r.avg_vote,r.avg_vote_reversed)}
            <Text style={styles.text1}>
                {r.num_votes > 1 ? `${r.num_votes} votes between ${r.min_date} and ${r.max_date}` : `${r.max_date}`}
            </Text>

            <Text style={styles.text1}>&#160;</Text>
        </React.Fragment>
        ))}
        
        <View style={styles.separator} />

        <View style={styles.footer}>
          <Text style={styles.footer_text}>Logged in as {email}</Text>
        </View>

      </View>
    </ScrollView>
  );

  return view;
}

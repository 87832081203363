import * as React from 'react';
import { Button, ScrollView, TextInput } from 'react-native';
import { Text, View } from '../components/Themed';
import { styles } from '../assets/css/styles';
import { BASE_URL, IS_DEBUG } from '../constants/Config';
import { translations } from '../constants/translations';
import { handleCcChange } from '../utils/ChangeHandlers';
import ReactGA from 'react-ga4';

//import ChatGPT from './utils/ChatGPT';

export default function AssistantScreen({navigation, route}) {

  //const { uid, fid, email } = route.params;

  // session values
  const uid = sessionStorage.getItem('uid');
  const fid = sessionStorage.getItem('fid');
  const email = sessionStorage.getItem('email');
  // translation
  const [cc, setCc] = React.useState( sessionStorage.getItem('cc') );
  let t = translations[cc] || translations.en;
  // states
  const [question, setQuestion] = React.useState("");
  const [answer, setAnswer] = React.useState("");
  
  // --------------------------------------------------------------------------------
  // handle cc changes
  // --------------------------------------------------------------------------------
  React.useEffect(() => {
    const wrappedHandleCcChange = () => handleCcChange(setCc);
    window.addEventListener('ccChange', wrappedHandleCcChange);
    return () => {
      window.removeEventListener('ccChange', wrappedHandleCcChange);
    };
  }, [cc]);

  // --------------------------------------------------------------------------------
  // tracking
  // --------------------------------------------------------------------------------
  React.useEffect(() => {
    // tracking
    const unsubscribe = navigation.addListener('focus', () => {
      const currentPath = window.location.pathname + window.location.search;
      ReactGA.send({hitType: "pageview", page: currentPath, title: "AssistantScreen"});
    });
    return unsubscribe;
  }, [navigation]);


  const query = async (q) => {
  
    //const c = `My name is Hinnerk and i live in Germany in a Family of four.`;
    
    // Test Questions:
    // I didn't sleep well and don't feel good. what can id do to sleep better? i also had a strange dream too.
    // Test Frage
    // Was bedeutet Familienglück?
    // Wie kann man sicherstellen das die eigene Famile glücklich ist?
    // TextGeneration Model
    const data = {"inputs": `${q}` };
    // C4AI Command R+
    // https://huggingface.co/CohereForAI/c4ai-command-r-plus (supports 10 languages)
    const apiKey = "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"; // hugging face
    const url = "https://api-inference.huggingface.co/models/CohereForAI/c4ai-command-r-plus";
    //const url = "https://api-inference.huggingface.co/models/google/gemma-7b";
    // QuestionAnswering Models
    //const data = {"inputs": { "question": `${q}`, "context": `${c}` } };
    //const url = "https://api-inference.huggingface.co/models/google-bert/bert-large-uncased-whole-word-masking-finetuned-squad";


    

    // need to check
    //const url = "https://api-inference.huggingface.co/models/bigscience/bloom";
    //const url = "https://api-inference.huggingface.co/models/google/gemma-7b";
    // Model requires a Pro subscription
    //const url = "https://api-inference.huggingface.co/models/meta-llama/Llama-2-7b-chat-hf";
    try {
      const response = await fetch(url, {
        headers: { 
          "Content-Type": "application/json",
          "Authorization": `Bearer ${apiKey}`, 
        },
        method: "POST",
        body: JSON.stringify(data),
      });
      
      const rspJson = await response.json();
  
      IS_DEBUG && console.log(`query >> rsp=${rspJson} data=${data}`);
      if ( rspJson.error ) {
        setAnswer(rspJson.error);
      } else if (rspJson[0] && rspJson[0].generated_text) { // c4ai
        setAnswer(rspJson[0].generated_text);
      } else {
        setAnswer(JSON.stringify(rspJson));
      }
  
    } catch (error) {
      console.error("[ERR] ", JSON.stringify(error));
    }
  }

  return (
    <ScrollView style={styles.container_scroll}>
        <View style={styles.container}>

            <Text style={styles.text4}>Family Assistant</Text>

            <View style={styles.separator} />

            <Text style={styles.text3}>{t.aiWelcomeMsg}?</Text>
            <Text style={styles.text3}>{t.aiNote}!</Text>
            <View style={styles.inputMultiView} >
                <TextInput
                    style={styles.inputMultiText}
                    placeholder="Question..."
                    placeholderTextColor="#003f5c"
                    multiline={true}
                    numberOfLines={4}
                    value={question}
                    onChangeText={(q) => setQuestion(q)} 
                    onSubmitEditing={() => query(question)} // Enter
                    />
            </View>
            <Button title="Send"  onPress={() => query(question)} />

            <View style={styles.separator} />

            <Text style={styles.text3}>Assistant:</Text>
            <Text style={styles.text3}>{answer}</Text>

            <View style={styles.separator} />
            {t.aiInfo}

            <View style={styles.separator} />
            <View style={styles.footer}>
              <Text style={styles.footer_text}>Logged in as {email}</Text>
            </View>

        </View>
    </ScrollView>
  );

}



import React, { Component } from "react";
import { StyleSheet, Text, TextInput, TouchableOpacity, View, Alert } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { BASE_URL, APP_NAME, IS_DEBUG } from '../constants/Config'
import { styles } from '../assets/css/styles';
import ReactGA from 'react-ga4';

//--------------------------------------------------------------------------------
// Register user
//--------------------------------------------------------------------------------
const registerUser = async (email:string, password1:string, password2:string) => {
  const registerURL = BASE_URL+'php/register.php';
  IS_DEBUG && console.log(">> Register  >> email="+email+" password1="+password1+" password2="+password2);
  try {
    const response = await fetch(registerURL, {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: `email=${email}&password1=${password1}&password2=${password2}`,
    });

    const jsonResponse = await response.json();

    if (jsonResponse.rc === 0) {
      IS_DEBUG && console.error('[OK] Register successful');
      return { success: true, message: jsonResponse.msg  };
    } else {
      IS_DEBUG && console.log(`[ERR] Login failed jsonResponse=${jsonResponse} `);
      return { success: false, message: jsonResponse.msg };
    }

  } catch (error) {
    IS_DEBUG && console.error('[ERR] Login error:', error);
    return { success: false, message: 'An error occurred during login.' };
  }

}

//--------------------------------------------------------------------------------
// Export function
//--------------------------------------------------------------------------------
export default function RegisterScreen({ navigation, route }) {


  const [email, setEmail] = React.useState(null);
  const [password1, setPassword1] = React.useState(null);
  const [password2, setPassword2] = React.useState(null);
  const [info, setInfo] = React.useState('');

  // --------------------------------------------------------------------------------
  // tracking
  // --------------------------------------------------------------------------------
  React.useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      const currentPath = window.location.pathname + window.location.search;
      ReactGA.send({hitType: "pageview", page: currentPath, title: "RegisterScreen"});
    });
    return unsubscribe;
  }, [navigation]);
  
  /**
  * Go to login screen
  **/
  const onPressLoginScreen = () => {
    navigation.navigate('Login', { email: email, password: password1 });
  }

  /**
  * Register
  **/
  const onPressRegister = async () => {
    const registerResult = await registerUser(email, password1, password2);
    if (registerResult.success) {
      setInfo(`Register done: ${registerResult.message}`);
      //navigation.navigate('Login', { rsp: rspJson });
    } else {
      IS_DEBUG && console.log(`[ERR] Register failed: ${registerResult.message} `);
      setInfo(`Register failed:  ${registerResult.message}`);
    }
  }


  return (
    <View style={styles.container_blue}>

      <Text style={styles.logo}>{APP_NAME}</Text>
      <View style={styles.inputView} >
        <TextInput
          style={styles.inputText}
          autoCapitalize="none"
          placeholder="Email"
          placeholderTextColor="#003f5c"
          onChangeText={text => setEmail(text)}/>
      </View>
      <View style={styles.inputView} >
        <TextInput
          secureTextEntry
          style={styles.inputText}
          placeholder="Password eingeben"
          placeholderTextColor="#003f5c"
          onChangeText={text => setPassword1(text)}/>
      </View>
      <View style={styles.inputView} >
        <TextInput
          secureTextEntry
          style={styles.inputText}
          placeholder="Password wiederholen"
          placeholderTextColor="#003f5c"
          onChangeText={text => setPassword2(text)}/>
      </View>
      <Text style={styles.placeholder}>
      </Text>
      <TouchableOpacity onPress={onPressLoginScreen}>
        <Text style={styles.btnText} >Login</Text>
      </TouchableOpacity>
      <TouchableOpacity onPress={onPressRegister} style={styles.btn}>
        <Text style={styles.btnText}>SIGNUP</Text>
      </TouchableOpacity>
      {info && 
        <>
          <Text style={styles.placeholder}></Text>
          <Text style={styles.feedback_pink}>{info}</Text>
        </>
      }

    </View>
  );
}

import React from 'react';
import 'chart.js/auto';
import { View } from 'react-native';
import { Line } from 'react-chartjs-2';
import { styles } from '../assets/css/styles';

const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'top',
      labels: {
        font: {
          size: 12,
        },
      },
    },
    title: {
      display: true,
      text: 'Line Chart (over time)',
      font: {
        size: 18,
      },
    },
  },
  scales: {
    x: {
      ticks: {
        font: {
          size: 12,
        },
      },
    },
    y: {
      ticks: {
        font: {
          size: 12,
        },
      },
    },
  },
};

const LineChart = ({ history }) => {
  const data = {
    labels: history.map(entry => entry.vote_date),
    datasets: [
      {
        label: 'Percentile (0-5)',
        backgroundColor: 'rgba(251, 91, 90, 0.4)', // Pink with alpha
        borderColor: '#fb5b5a', // Pink
        data: history.map(entry => parseFloat(entry.avg_vote)),
      },
      {
        label: 'Number of Votes',
        backgroundColor: 'rgba(0, 63, 92, 0.4)', // Blue with alpha
        borderColor: '#003f5c', // Blue
        data: history.map(entry => parseFloat(entry.num_votes)),
      },
    ],
  };

  return (
    <View style={styles.chartContainer}>
      <Line options={options} data={data} />
    </View>
  );
};

export default LineChart;


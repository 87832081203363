import * as React from 'react';
import { ScrollView, Switch, TextInput } from 'react-native';
import { Text, View } from '../components/Themed';
import { styles } from '../assets/css/styles';
import { BASE_URL, IS_DEBUG } from '../constants/Config';
import { translations } from '../constants/translations';
import { handleCcChange } from '../utils/ChangeHandlers';
import ReactGA from 'react-ga4';
import {Picker} from '@react-native-picker/picker';

// Multilingual app
import { useTranslation } from 'react-i18next';

function Greeting() {
  const { t } = useTranslation();
  return <h2>{t('greeting')}</h2>;
}

export default function ConfigScreen({navigation, route}) {

  //const { uid, fid, email } = route.params;
  const uid = sessionStorage.getItem('uid');
  const fid = sessionStorage.getItem('fid');
  const email = sessionStorage.getItem('email');
  
  const [refreshTime, setRefreshTime] = React.useState(null);
  const [sources, setSources] = React.useState(null); 
  /* [{ id: 1, name: 'Custom Questions', enabled: true }, { id: 2, name: 'Oxford Happiness Test', enabled: true }]; */

  // translation
  const [cc, setCc] = React.useState( sessionStorage.getItem('cc') );
  let t = translations[cc] || translations.en;
  
  // --------------------------------------------------------------------------------
  // handle cc changes
  // --------------------------------------------------------------------------------
  React.useEffect(() => {
    const wrappedHandleCcChange = () => handleCcChange(setCc);
    window.addEventListener('ccChange', wrappedHandleCcChange);
    return () => {
      window.removeEventListener('ccChange', wrappedHandleCcChange);
    };
  }, [cc]);
  
  // --------------------------------------------------------------------------------
  // init data loading and tracking
  // --------------------------------------------------------------------------------
  React.useEffect(() => {
    // getConfig
    getConfig(fid);
    // tracking
    const unsubscribe = navigation.addListener('focus', () => {
      const currentPath = window.location.pathname + window.location.search;
      ReactGA.send({hitType: "pageview", page: currentPath, title: "ConfigScreen"});
    });
    return unsubscribe;
  }, [navigation, cc]);

  // get config for family
  const getConfig = async (fid) => {
    const url = BASE_URL+'php/get_config.php';
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: `fid=${fid}`,
      });
      const rspJson = await response.json();
      if (rspJson.rc === 1 ) {  
        IS_DEBUG && console.log(`[OK] getConfig rc=${rspJson.rc}`);
        setRefreshTime(rspJson.refresh_time);
        setSources(rspJson.source);
        setCc(rspJson.cc);
      } else {
        console.log(`[ERR] rc=${rspJson.rc} msg=${rspJson.msg}`);
      }
    } catch (error) {
      console.log(`[ERR] error=${error}`);
    }
  }
  
  // update refresh time in database
  const updateRefreshTime = async (sec) => {
    const url = BASE_URL+'php/update_refresh_time.php';
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: `fid=${fid}&sec=${sec}`,
      });
      const rspJson = await response.json();
      if (rspJson.rc === 1 ) {  
        IS_DEBUG && console.log(`[OK] updateRefreshTime rc=${rspJson.rc}`);
        setRefreshTime(sec);
      } else {
        console.log(`[ERR] rc=${rspJson.rc} msg=${rspJson.msg}`);
      }
    } catch (error) {
      console.log(`[ERR] error=${error}`);
    }
  }
  
  // update source status 1=on 0=off
  const updateSourceStatus = async (sourceId, enabled) => {
    const url = BASE_URL+'php/update_source_status.php';
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: `fid=${fid}&sid=${sourceId}&enabled=${enabled}`,
      });
      const rspJson = await response.json();
      if (rspJson.rc === 1 ) {  
        IS_DEBUG && console.log(`[OK] updateSourceStatus rc=${rspJson.rc}`);
        setSources((prevSource) =>
            prevSource.map((source) =>
                source.id === sourceId ? { ...source, enabled: !source.enabled } : source
            )
        );
      } else {
        console.log(`[ERR] rc=${rspJson.rc} msg=${rspJson.msg}`);
      }
    } catch (error) {
      console.log(`[ERR] error=${error}`);
    }
  };

  // updateCC
  const updateCC = async (cc) => {
    const url = BASE_URL+'php/update_cc.php';
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: `fid=${fid}&cc=${cc}`,
      });
      const rspJson = await response.json();
      if (rspJson.rc === 1 ) {  
        IS_DEBUG && console.log(`[OK] updateCC rc=${rspJson.rc}`);
        sessionStorage.setItem('cc', cc);
        setCc(cc);
        // inform other screens that cc changed
        const event = new Event('ccChange');
        window.dispatchEvent(event);
      } else {
        console.log(`[ERR] rc=${rspJson.rc} msg=${rspJson.msg}`);
      }
    } catch (error) {
      console.log(`[ERR] error=${error}`);
    }
  };

  return (
    <ScrollView style={styles.container_scroll}>
        <View style={styles.container}>

            <Text style={styles.text4}>Configuration</Text>

            <View style={styles.separator} />

            <Text style={styles.text3}>Question Refresh time</Text>
            <View style={styles.inputView} >
                <TextInput
                    style={styles.inputText}
                    placeholder="Seconds..."
                    placeholderTextColor="#003f5c"
                    defaultValue={refreshTime}
                    onChangeText={refreshTime => updateRefreshTime(refreshTime)}/>
            </View>

            <View style={styles.separator} />

            <Text style={styles.text3}>Source of Questions</Text>
            {sources?.map((source) => (
                <View key={source.id} style={styles.switchContainer}>
                    <Text style={styles.switchLabel}>{source.name}</Text>
                    <Switch
                        value={source.enabled}
                        onValueChange={() => updateSourceStatus(source.id, !source.enabled)}/>
                </View>
            ))}

            <View style={styles.separator} />

            <Text style={styles.text3}>Language</Text>
            <Picker
              selectedValue={cc}
              onValueChange={(itemValue, itemIndex) =>
                updateCC(itemValue)
              }>
              <Picker.Item label="English" value="en" />
              <Picker.Item label="German" value="de" />
            </Picker>

            <View style={styles.separator} />
            
            {/* <Greeting /> */}

            <View style={styles.footer}>
                {/* {t('greeting', {email})} */}
                <Text style={styles.footer_text}>Logged in as {email}</Text>
            </View>


        </View>
    </ScrollView>
  );

}


